import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AboutQcl.css';
import Navbar from "../Navbar/Navbar";

const AboutQcl = () => {
  return (
    <>
    <Navbar /><br /><br /><br /><br />
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-2 col-md-3 col-sm-4 col-xs-12 p-3 " style={{backgroundColor: '#071A44'}}>
          <div className="nav flex-column nav-pills " id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <a className="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">What is QCL?</a>
            <a className="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-phones" role="tab" aria-controls="v-pills-profile" aria-selected="false">Sell Phones</a>
            <a className="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-games" role="tab" aria-controls="v-pills-profile" aria-selected="false">Sell Games & Consoles</a>
            <a className="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-tech" role="tab" aria-controls="v-pills-profile" aria-selected="false">Sell Tech</a>
            <a className="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-history" role="tab" aria-controls="v-pills-profile" aria-selected="false">QCL history</a>
            <a className="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-people" role="tab" aria-controls="v-pills-profile" aria-selected="false">QCL people</a>
            <a className="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-press" role="tab" aria-controls="v-pills-profile" aria-selected="false">Press Clippings</a>
            <a className="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-logo" role="tab" aria-controls="v-pills-profile" aria-selected="false">QCL logo</a>
            <a className="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-gender" role="tab" aria-controls="v-pills-profile" aria-selected="false">Gender pay gap</a>
            <a className="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-slavery" role="tab" aria-controls="v-pills-profile" aria-selected="false">Modern slavery statement</a>
            <a className="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-covid" role="tab" aria-controls="v-pills-profile" aria-selected="false">Our COVID-19 response</a>
            <a className="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-policy" role="tab" aria-controls="v-pills-profile" aria-selected="false">Counterfeit products policy</a>
            {/* Add more items as needed */}
          </div>
        </div>
        <div className="col-lg-10 col-md-9 col-sm-8 col-xs-12">
          <div className="tab-content" id="v-pills-tabContent">
            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
              {/* Content and Product cards for Item 1 */}
              <div className="row">
                <div className="col-md-12">
                  <h2>What is QCL?</h2>
                  <p>QCL is the UK’s biggest second-hand electronics and entertainment specialist. You can buy, sell and 
                    exchange your games, phones, consoles, laptops, tablets, computers, electronics & gadgets for cash.
                    Starting as a single games and computer specialist shop just off of London’s Tottenham Court Road in 1992, 
                    QCL has expanded over 30 years to more than 600 stores worldwide, spanning over 11 countries including the UK, 
                    Ireland, Spain, Portugal, Mexico, The Canaries, Malaysia, The Netherlands, Australia, India and Italy. QCL has 
                    been serving tech enthusiasts, gamers and geeks the latest and greatest in the world’s tech and gaming - all with
                    a 24 month warranty, for decades.At QCL you can get cash fast for your old phones, games, consoles, laptops, tablets,
                    computers, technology, DVDs, Blu-Rays & gadgets, or get more with a QCL voucher and upgrade your old phones, consoles and laptops for less. With 385+ stores in the UK, you can find your local store here.Upgrade your tech for less by trading your unwanted tech in at QCL, and grabbing yourself that new phone, new games, consoles, laptops and tech. You can shop in-store, online and now you can skip the queues
                    and make sure you reserve your goodies with Click & Collect at QCL.</p>
                    <p>QCL is the UK’s biggest second-hand electronics and entertainment specialist. You can buy, sell and 
                    exchange your games, phones, consoles, laptops, tablets, computers, electronics & gadgets for cash.
                    Starting as a single games and computer specialist shop just off of London’s Tottenham Court Road in 1992, 
                    QCL has expanded over 30 years to more than 600 stores worldwide, spanning over 11 countries including the UK, 
                    Ireland, Spain, Portugal, Mexico, The Canaries, Malaysia, The Netherlands, Australia, India and Italy. QCL has 
                    been serving tech enthusiasts, gamers and geeks the latest and greatest in the world’s tech and gaming - all with
                    a 24 month warranty, for decades.At QCL you can get cash fast for your old phones, games, consoles, laptops, tablets,
                    computers, technology, DVDs, Blu-Rays & gadgets, or get more with a QCL voucher and upgrade your old phones, consoles and laptops for less. With 385+ stores in the UK, you can find your local store here.Upgrade your tech for less by trading your unwanted tech in at QCL, and grabbing yourself that new phone, new games, consoles, laptops and tech. You can shop in-store, online and now you can skip the queues
                    and make sure you reserve your goodies with Click & Collect at QCL.</p>
                    <p>QCL is the UK’s biggest second-hand electronics and entertainment specialist. You can buy, sell and 
                    exchange your games, phones, consoles, laptops, tablets, computers, electronics & gadgets for cash.
                    Starting as a single games and computer specialist shop just off of London’s Tottenham Court Road in 1992, 
                    QCL has expanded over 30 years to more than 600 stores worldwide, spanning over 11 countries including the UK, 
                    Ireland, Spain, Portugal, Mexico, The Canaries, Malaysia, The Netherlands, Australia, India and Italy. QCL has 
                    been serving tech enthusiasts, gamers and geeks the latest and greatest in the world’s tech and gaming - all with
                    a 24 month warranty, for decades.At QCL you can get cash fast for your old phones, games, consoles, laptops, tablets,
                    computers, technology, DVDs, Blu-Rays & gadgets, or get more with a QCL voucher and upgrade your old phones, consoles and laptops for less. With 385+ stores in the UK, you can find your local store here.Upgrade your tech for less by trading your unwanted tech in at QCL, and grabbing yourself that new phone, new games, consoles, laptops and tech. You can shop in-store, online and now you can skip the queues
                    and make sure you reserve your goodies with Click & Collect at QCL.</p>
                </div>
              </div>
              {/* Product cards for Item 1 */}
              <div className="row">
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 1
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 2
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 3
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 4
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="v-pills-phones" role="tabpanel" aria-labelledby="v-pills-profile-tab">
             {/* Content and Product cards for Item 1 */}
             <div className="row">
                <div className="col-md-12">
                  <h2>Sell Phones</h2>
                  <h3>Wanted: iPhones!</h3>
                   <p>Get cash for your tech the easy way at QCL. With new iPhones 
                    on the way, now is the best time to sell your phone for the best price. Discover the value of your old 
                    tech at QCL and get your next phone for less.</p>
                </div>
              </div>
              {/* Product cards for Item 1 */}
              <div className="row">
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 1
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 2
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 3
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 4
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="v-pills-games" role="tabpanel" aria-labelledby="v-pills-profile-tab">
             {/* Content and Product cards for Item 1 */}
             <div className="row">
                <div className="col-md-12">
                  <h2>Sell Games & Consoles</h2>
                  <h3>Sell Games & Consoles for CASH</h3>
                   <p>Don’t let your unwanted consoles gather dust. Get top cash for them 
                    in-store or online today! Click the buttons below to see how much you could get</p>
                </div>
              </div>
              {/* Product cards for Item 1 */}
              <div className="row">
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 1
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 2
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 3
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 4
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="v-pills-tech" role="tabpanel" aria-labelledby="v-pills-profile-tab">
             {/* Content and Product cards for Item 1 */}
             <div className="row">
                <div className="col-md-12">
                  <h2>Sell Tech</h2>
                  <h3>Sell Tech for CASH</h3>
                   <p>Don't let your unwanted tech gather dust. Get top cash for it in-store or 
                    online today! Click the buttons below to see how much you could get.</p>
                </div>
              </div>
              {/* Product cards for Item 1 */}
              <div className="row">
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 1
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 2
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 3
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 4
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="v-pills-history" role="tabpanel" aria-labelledby="v-pills-profile-tab">
             {/* Content and Product cards for Item 1 */}
             <div className="row">
                <div className="col-md-12">
                  <h2>QCL History</h2>
                   <p>Crumbs. Doesn't time fly? It seems like only yesterday when QCL opened its doors for the first time. 
                    From little nippers to the oldest kids in the classroom, the founders still run the show, keeping it fiercely 
                    independent, passionately un-corporate, yet always professional. Well, we don't really run the show, we just pretend 
                    to; our custies who shop with us and now work with us run the show. Our testers, 
                    sales assistants, supervisors and management run this very special show in a very special way.</p>
                    <p>Humour us, please, while we take a misty-eyed meander down memory lane. Don't worry... we'll spare you the photo 
                    album.Started by Robert Dudani (Drinking Buddy), Paul Farrington (Gig Buddy), Hugh Man (Zen Buddy), Charlie Brooker 
                    (Cynical Student Type Buddy), Oli Smith (Genius Buddy) and Oliver Ball (The Consigliere).</p>
                    <p>A long, long time ago, in a galaxy far, far away - well, 1992 in London to be exact, as we couldn't find Tatooine - 
                    we had three grand in our back pocket and a firm belief that there is a second hand market for computers, gadgets and 
                    video games. Increasing obsolescence was rapidly increasing the need to recycle.</p>
                    <p>We also thought that the staff at Rumbelows were crap - we, as custies, wanted to know what we were buying, in 
                    ever-increasing technical gadgets and silicon, and ever-increasing catalogues of games and movies.</p>
                    <p>We couldn't afford the most exotic kit or get rid of our old kit. What's a geek to do?</p>
                </div>
              </div>
              {/* Product cards for Item 1 */}
              <div className="row">
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 1
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 2
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 3
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 4
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="v-pills-people" role="tabpanel" aria-labelledby="v-pills-profile-tab">
             {/* Content and Product cards for Item 1 */}
             <div className="row">
                <div className="col-md-12">
                <h2>QCL People</h2>
                <p>Have you fallen desperately and deeply in love with a QCL employee and want to put a name to the 
                face? Don't worry, it happens all the time*. Use this section find contact details for people in all 
                parts of the QCL empire.*Not strictly true.</p>
                <h3>QCL Executive Board !!!!!</h3>
                <p>Oooh. These are the big, scary bosses. They don't have sandwiches for lunch like the rest of us, they eat raw 
                quail eggs and caviar. They don't drink beer, they quaff Chateau Rothschild 1996. If they went to the 
                toilet - which they don't, 'cos they're royal - they'd use £50 notes instead of Andrex.</p>
                <p>If you want to spot them when they're out and about, just look for the teams of personal servants, 
                specifically employed to scatter rose petals in front of them wherever they go (a word of warning, though: this can 
                get a bit embarrasing if you ever have to share a tube carriage with one of them).</p>
                <h3>QCL Commercial & Strategy</h3>
                <p>Not only do these clever souls get hold of all those lovely new games, hardware and DVDs that we sell, 
                they are also responsible for making sure we stay the cheapest, most value-laden place to buy your stuff. Hurrah for 
                them, then.</p>
                <h3>Customer Service</h3>
                <p>These courteous individuals handle all customer enquiries to QCL. From Ballymena to Wolverhampton, they will deal 
                with all your questions; Sales, Trade-ins, the whole lot. [Go on, give us a shout.](/site/support).</p>
                <h3>E-Commerce</h3>
                <p>Don't live near QCL, too lazy to travel to one of our stores? No problem! [Get in touch](/site/support), let them 
                do the dirty work for you.</p>
                <h3>QCL Accounts</h3>
                <p>These are the people who hand out our wages, so they are officially the Best People In The World Ever. They also 
                make lots of risque jokes about double-entry... Accountants find that sort of thing funny, apparently.</p>
                <h3>QCL HR</h3>
                <p>Fancy working for the funkiest videogames and PC retailer in the whole wide world? These are the people to talk to.</p>
                <h3>QCL IT</h3>
                <p>Made up from a team of highly evolved beings, the QCL IT team is capable of breaking - and then 
                fixing - computers in ways that mere mortal minds can't even comprehend. If that fails, there's always turning it off 
                and back on again.</p>
                <h3>QCL Prevention</h3>
                <p>This is our superhero team of health and safety checkers and crime fighters. If anyone is ever daft enough to try 
                and pilfer something from our stores, it'll be QCL Prevention's hands on their shoulder. The would-be criminal will 
                then be subjected to a five-hour lecture on the importance of workplace safety. So, remember kids... crime doesn't 
                pay!</p>

                </div>
              </div>
              {/* Product cards for Item 1 */}
              <div className="row">
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 1
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 2
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 3
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 4
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="v-pills-press" role="tabpanel" aria-labelledby="v-pills-profile-tab">
             {/* Content and Product cards for Item 1 */}
             <div className="row">
                <div className="col-md-12">
                  <h2>Press Clipings</h2>
                </div>
              </div>
              {/* Product cards for Item 1 */}
              <div className="row">
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 1
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 2
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 3
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 4
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="v-pills-logo" role="tabpanel" aria-labelledby="v-pills-profile-tab">
             {/* Content and Product cards for Item 1 */}
             <div className="row">
                <div className="col-md-12">
                  <h2>About Paul</h2>
                   <p>Paul Farrington, an experienced diver and one of the Computer Exchange's founding directors, 
                    was listed as missing after exploring an underwater wreck near Mullion Island, Cornwall on Monday (1 May 2000).</p>
                   <p>Paul imbued the Computer Exchange with his spirit. At its inception in 1992 he played a central role in 
                    creating its philosophy of free trade in goods for all. His enlightened understanding of human nature and personal 
                    warmth informed us all. He will be sorely missed by his many friends at the Computer Exchange.</p>
                    <p>Paul's extraordinary career and farsighted approach to technology and the market place helped us develop 
                    our unique way of doing business. As a music journalist, technological pioneer and diver, Paul approached everything 
                    with a profound enthusiasm.</p>
                    <p>The Computer Exchange is with his family and many friends in this hour, and offers them all the support at our disposal.</p>
                    <p>It has come to our attention that some of the press reports regarding Paul have been inaccurate. To clarify issues;</p>
                    <ul>
                        <li>Paul Farrington is English and was born on 15/2/61.</li>
                        <li>Paul Farrington was a founding Director of Computer Exchange with Robert Dudani</li>
                        <li>Paul Farrington was highly regarded within both the computer and the video game industries</li>
                        <li>He was not married and has no children</li>
                        <li>He gained a Degree in Philosophy</li>
                        <li>Paul Farrington lived in Camden Town, north London</li>
                    </ul>
                    <p>Many thanks to NTK, the weekly IT newsletter, for the following tribute to Paul</p>
                    <p>Paul Farrington, co-founder of the QCL, the Computer Exchange in Tottenham Court Road, 
                    disappeared while exploring an underwater wreck off the coast of Cornwall last Tuesday. Anyone who has spent any 
                    time at QCL (and especially those who spent the bulk of their teenage years hanging out there) might have guessed 
                    that its inspiration came from more than the usual flogging of dodgy second-hand kit</p>
                    <p>The compulsory smoking rules, the bizarrely knowledgeable staff, the eclectic (and tympanum-crunchingly loud) 
                    musical choices: Judging from the messages of shock and love we've heard, Paul was a great and unique part of the QCL 
                    ambience. "He was a surrogate father to a generation of geeks", said one correspondent. Charlie Brooker writes:</p>
                    <p>One of my enduring memories of Paul is the day I first met him; I'd turned up for my first day at work in the 
                    computer department of Music and Video Exchange in Notting Hill, and walked in to find nobody there. I sat behind the 
                    counter for about half an hour, not really knowing what to do, and was about to leave when a tall, gangly man wearing 
                    pink tights and a fcking fez* stalked in, looked confused for a while, and then asked me who I was and what I was 
                    doing there. I really wasn't sure whether to run away or not. I'm glad I didn't. Once you got used to the fact he 
                    dressed like a maniac (and I particularly remember an incredible haircut he once had which made him look like a cross 
                    between Coolio and an exploding picnic basket), he became an incredibly likeable, inventive, anarchic, and weirdly 
                    trustworthy companion. I always looked up to him, even when he convinced me to shave all my hair off apart from one 
                    off-centre tuft, and I spent an entire month watching strangers cross the street when they saw me coming.</p>
                    <p>Probably the simplest thing I could say about him is this: that he was easily the coolest person I've ever met, 
                    and my head refuses to believe he's gone.</p>
                </div>
              </div>
              {/* Product cards for Item 1 */}
              <div className="row">
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 1
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 2
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 3
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 4
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="v-pills-gender" role="tabpanel" aria-labelledby="v-pills-profile-tab">
             {/* Content and Product cards for Item 1 */}
             <div className="row">
                <div className="col-md-12">
                  <h2>Gender Pay Gap</h2>
                  <p>The reports below sets out our findings and provides some context to explain QCL gender pay gap figures.</p>
                  <p>Download our Gender Pay Gap report 2022 here (pdf)</p>
                  <p>Download our Gender Pay Gap report 2021 here (pdf)</p>
                  <p>Download our Gender Pay Gap report 2020 here (pdf)</p>
                  <p>Download our Gender Pay Gap report 2019 here (pdf)</p>
                  <p>Download our Gender Pay Gap report 2018 here (pdf)</p>
                  <p>Download our Gender Pay Gap report 2017 here (pdf)</p>
                </div>
              </div>
              {/* Product cards for Item 1 */}
              <div className="row">
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 1
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 2
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 3
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 4
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="v-pills-slavery" role="tabpanel" aria-labelledby="v-pills-profile-tab">
             {/* Content and Product cards for Item 1 */}
             <div className="row">
                <div className="col-md-12">
                  <h2>Modern Slavery Statement</h2>
                  <h3>Introduction</h3>
                  <p>This statement is made pursuant to section 54 of the Modern Slavery Act 2015. 
                  It constitutes QCL slavery and human trafficking statement for the financial year ended 31 December 2018.</p>
                  <h3>Our Organization</h3>
                  <p>QCL was founded in London in 1992. We have stores in the UK, Spain, Ireland, India, Australia, Portugal, 
                  Netherlands, Mexico, Poland and Canarias.</p>
                  <p>We buy, sell and exchange a range of technology and entertainment products including mobile phones, 
                  video games, DVDs and Blu-ray movies, computers, digital electronics, TVs and monitors, and music CDs.</p>
                  <p>We have more than 700 stores worldwide and we are growing.</p>
                  <h3>Our Supply Chain</h3>
                  <p>As a second hand trade company our supply chain is the customer. We do however work with third party 
                  companies who act as a support service to our needs.</p>
                  <h3>Policies</h3>
                  <p>https://dev-portal.cex.webuy.dev/transfer-group-managerAs an expanding business, we are in the process 
                  of reviewing our policies in order to make sure that our third parties understand our expectations with 
                  regards to human rights across all areas of our supply chain.</p>
                  <p>QCL actively encourages employees to report any activity that they believe is in breach of company 
                  human rights standards, for this we have a whistleblowing procedure in place to report any concerns about 
                  the way we and our third parties perform our tasks.</p>
                  <h3>Due Diligence and Risk Assessment</h3>
                  <p>QCL is fortunate that out supply is from the general public, but we still use support services to 
                  assist with the day to day running of our business. Many of these parties already have robust Due Diligence 
                  measures in place.</p>
                  <p>As part of the ongoing improvement to QCL policies and standards, we are ensuring that we have a more 
                  comprehensive approach to risk assessment and identification of risk. In 2018 we made substantial improvements 
                  to our compliance department to assist in reviewing policies and procedures belonging to ourselves and to our third 
                  parties.</p>
                  <p>Where applicable we visit our third parties to ensure their working conditions, policies and procedures are up 
                  to an excellent standard. Given the geographical location of some of our third parties, we are committed to 
                  understanding our relationships better by ensuring transparency with strong working contracts.</p>
                  <h3>Measurement of Effectiveness</h3>
                  <p>QCL will continue to monitor our third party support services both existing and new. Should any instances of 
                  non-compliance with the Act arise in relation to any of our third parties then this will be reviewed.</p>
                </div>
              </div>
              {/* Product cards for Item 1 */}
              <div className="row">
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 1
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 2
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 3
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 4
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="v-pills-covid" role="tabpanel" aria-labelledby="v-pills-profile-tab">
             {/* Content and Product cards for Item 1 */}
             <div className="row">
                <div className="col-md-12">
                  <h2>How QCL is responding to coronavirus (COVID-19)</h2>
                  <p>As we look to open our stores again to the public 
                   we have composed this page to give you an idea of what has changed to ensure we follow government guidelines.</p>
                  <h3>What to expect in our stores to keep you and our staff safe.Social distancing</h3>
                  <p>During our busier times we may limit the amount of customers entering the store, where possible there will 
                  be queue guidance outside the store. To help you maintain a distance of two metres from other customers and staff, 
                  you'll find floor markings inside our stores to advise social distancing. We've also put up Perspex protector 
                  screens at our tills areas to keep our dedicated staff safe.</p>
                  <p>PPE has also been stocked efficiently to ensure that safety options are available.</p>
                  <p>We have also made careful alterations to our stores who operate with the option of using MePOS devices 
                  (used for Mobile customer engagement on the shop floor). All stores that have both MePOS and a Till system 
                  available will revert to only a Till system being used to assist with the safety of our customers and staff</p>
                  <h3>Contactless payments & Vouchers</h3>
                  <p>We are encouraging you to use contactless card payment where possible. The nationwide limit on contactless 
                  card payments has increased from £30 to £45 to reduce physical contact at checkout. We are of course fully 
                  honouring any vouchers you have in your possession.</p>
                  <h3>Demoing of an item you wish to buy</h3>
                  <p>Due to social distancing and the necessary changes to have both your safety and our staff's safety in 
                  mind, our staff will provide the demos behind the counter and will offer you the opportunity to inspect only 
                  once you have purchased. We apologise for the inconvenience this may cause but we hope this does not discourage 
                  you from purchasing your goods.</p>
                  <h3>Trading in your goods consideration</h3>
                  <p>We are still accepting your goods for cash or vouchers. What we ask in return is to be considerate in the 
                  cleanliness of the item when bringing into store. Where possible, sanitize your items and place in a bag so our 
                  staff can take out without any further interaction from yourself. We will, of course, clean the product before 
                  placing the item back out for sale.</p>
                  <p>Where at all possible, we encourage you to accept a bank transfer for any exchange for cash. Vouchers will of course 
                  still be offered.</p>
                  <h3>Changes to store operating hours</h3>
                  <p>In these unprecedented times, our stores may change their opening hours as they see necessary. We are working hard 
                  to reflect these changes as quickly as possible on our store locator but please be aware, information may not be 
                  accurate.</p>
                  <p>If you are feeling unwell and have any of the COVID-19 symptoms</p>
                </div>
              </div>
              {/* Product cards for Item 1 */}
              <div className="row">
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 1
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 2
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 3
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 4
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="v-pills-policy" role="tabpanel" aria-labelledby="v-pills-profile-tab">
             {/* Content and Product cards for Item 1 */}
             <div className="row">
                <div className="col-md-12">
                  <h2>Counterfeit Products Policy</h2>
                   <p>We do not buy or sell counterfeit products in QCL in stores or via webuy.com.</p>
                   <h3>What is the policy?</h3>
                   <p>Counterfeit products are illegal and not allowed on webuy.com or in QCL stores.</p>
                   <h3>Examples include:</h3>
                   <ul>
                    <li>Electronics or accessories that use or bear the brand name, logo, or trademark of a company that 
                    didn't manufacture the item.</li>
                    <li>Pirated or fake copies of movies, games or other software.</li>
                   </ul>
                   <p>Activity that doesn't follow QCL’s policy in regards to this may result in the closure of your 
                    account in addition to other actions.</p>
                    <h3>Why does CeX have this policy?</h3>
                    <p>To prevent the sale of counterfeit products, which are illegal and may impact on future customers</p>
                </div>
              </div>
              {/* Product cards for Item 1 */}
              <div className="row">
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 1
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 2
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 3
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      Product 4
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Add more tab panes for additional items */}
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default AboutQcl;