import React from "react";
import Navbar from "../Navbar/Navbar";
import Banar from "../Banar/Banar";
import Slider from "../Slider/Slider";
import Footer from "../Footer/Footer";
import { Link, useNavigate } from "react-router-dom";

const Home = () => {
  return (
    <>
      <div className="container-fluid pr-0">
        {/* <Navbar /> */}
        <br />
        <Banar />
        <Slider />
        <Footer />
      </div>
    </>
  )
}
export default Home; 