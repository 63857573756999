import React from 'react';
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

const PolicyPage = () => {
  return (
    <>
   <Navbar />
   <div style={{margin: '50px', padding: '20px'}}>
      <h1>Privacy Policy</h1>
      <p>
      At Quick Cash Leicester (QCL), we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the information gathered through our website and services. By accessing or using our website, you agree to the terms of this Privacy Policy.

      </p>
      <h2>1. Information We Collect</h2>
      <span style={{fontWeight: 'bold'}}>a. Personal Information: </span> When you use our website or services, we may collect personal information such as your name, email address, phone number, postal address, and payment details. This information is collected voluntarily when you submit it through our website or provide it during the selling process.<br />
      <span style={{fontWeight: 'bold'}}>b. Device Information:</span>We may also collect information about the devices you sell to us, including make, model, serial number, and condition. This information helps us evaluate the device and provide accurate pricing.<br />
      <span style={{fontWeight: 'bold'}}>c. Usage Data:</span>  We may collect non-personal information about your interactions with our website, such as your IP address, browser type, operating system, and pages visited. This information is collected automatically using cookies and similar technologies.
<br />
 
      <h2>2. Use of Information</h2>
      <p>
      <span style={{fontWeight: 'bold'}}>a. Transaction Processing:</span> We use the information collected to process transactions, verify device ownership, and facilitate payments for devices sold to us.<br />
      <span style={{fontWeight: 'bold'}}>b. Customer Service:</span> We may use your information to communicate with you regarding your transactions, respond to inquiries, and provide customer support.<br />
      <span style={{fontWeight: 'bold'}}>c. Improvement of Services:</span> We may analyze usage data to improve our website, services, and user experience. This includes optimizing website performance, enhancing security measures, and personalizing content.<br />

      </p>
      <h2>3. Disclosure of Information</h2>
      <span style={{fontWeight: 'bold'}}>a. Third-Party Service Providers:</span> We may share your information with third-party service providers who assist us in operating our website, processing transactions, or providing support services. These service providers are bound by confidentiality agreements and are prohibited from using your information for any other purpose.<br />
      <span style={{fontWeight: 'bold'}}>b. Legal Compliance:</span> We may disclose your information as required by law or to comply with legal obligations, enforce our Terms and Conditions, or protect the rights, property, or safety of Quick Cash Leicester, our customers, or others.<br />

      
      <h2>4. Data Security</h2>
      <span style={{fontWeight: 'bold'}}>a.</span> We implement appropriate security measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction. This includes encryption, secure payment processing, and restricted access to sensitive data.<br />
      <span style={{fontWeight: 'bold'}}>b.</span> While we strive to maintain the security of your information, no method of transmission over the internet or electronic storage is completely secure. Therefore, we cannot guarantee the absolute security of your data.<br />

      <h2>5. Your Choices and Rights</h2>
      <span style={{fontWeight: 'bold'}}>a. Access and Update:</span>You have the right to access, update, or correct your personal information at any time. You can do this by logging into your account on our website or contacting us directly.<br />
      <span style={{fontWeight: 'bold'}}>b. Opt-Out:</span>You may choose to opt-out of receiving marketing communications from us by following the instructions provided in the communication or contacting us.<br />

      <h2>6. Cookies Policy</h2>
      <span style={{fontWeight: 'bold'}}>a. Cookies:</span>We use cookies and similar technologies to collect information about your browsing activities and enhance your experience on our website. You can control cookies through your browser settings and opt-out of certain types of cookies.<br />
      <span style={{fontWeight: 'bold'}}>b. Third-Party Cookies:</span>We may also allow third-party cookies on our website for analytics, advertising, and other purposes. These cookies are subject to the privacy policies of the third parties providing them.<br />

      <h2>7. Changes to Privacy Policy</h2>
      <span style={{fontWeight: 'bold'}}>a. </span>We reserve the right to modify or update this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised policy on our website. We encourage you to review this Privacy Policy periodically for updates.<br />

      <h2>8. Contact Us</h2>
      <p>
      If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at contact@quickcashleicester.com.<br /><br />
Thank you for trusting Quick Cash Leicester with your personal information. We are committed to safeguarding your privacy and providing a secure and transparent experience.


      </p>
      
    </div>
    <Footer />
    </>
  );
};

export default PolicyPage;
