import React from 'react';
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

const CookiesPage = () => {
  return (
    <>
   <Navbar />
    <div style={{margin: '50px', padding: '20px'}}>
      <h1>Cookies Policy</h1>
      <p>
      At Quick Cash Leicester (QCL), we use cookies and similar technologies to enhance your browsing experience, analyze website traffic, and personalize content. This Cookies Policy explains how we use cookies, what types of cookies we use, and how you can manage your cookie preferences.
      </p>
      <h2>1. What are Cookies?</h2>
      <p>
      Cookies are small text files stored on your device (computer, smartphone, tablet) when you visit a website. They serve various purposes, such as remembering your preferences, tracking your activities on the website, and providing a personalized experience.

      </p>
      <h2>2. Types of Cookies We Use</h2>
      <p>
      <span style={{fontWeight: 'bold'}}>a. Essential Cookies:</span> These cookies are necessary for the functioning of our website and enable core functionalities such as security, authentication, and accessibility. You cannot opt-out of essential cookies as they are required for the website to operate.<br />
      <span style={{fontWeight: 'bold'}}>b. Analytics Cookies:</span> We use analytics cookies to gather information about how visitors interact with our website, such as the pages visited, time spent on each page, and navigation patterns. This data helps us improve our website's performance and user experience.<br />
      <span style={{fontWeight: 'bold'}}>c. Preference Cookies:</span> Preference cookies allow our website to remember information that changes the way the site behaves or looks, such as your preferred language or region.<br />
      <span style={{fontWeight: 'bold'}}>d. Marketing Cookies:</span> Marketing cookies are used to track visitors across websites and display relevant advertisements based on their interests and browsing behavior. These cookies may be set by our advertising partners.

      </p>
      <h2>3. Managing Cookies</h2>
      <p>
      You can control and manage cookies through your browser settings. Most web browsers allow you to block or delete cookies from specific websites or all websites. However, please note that blocking cookies may affect your browsing experience and prevent certain features from functioning properly.

      </p>
      <h2>4. Third-Party Cookies</h2>
      <p>
      We may also allow third-party cookies on our website for advertising, analytics, and other purposes. These cookies are subject to the privacy policies of the third parties providing them. We do not have control over third-party cookies, and they are governed by the respective third-party policies.

      </p>
      <h2>5. Consent</h2>
      <p>
      By continuing to use our website, you consent to the use of cookies as described in this Cookies Policy. You have the option to accept or decline cookies through your browser settings. If you choose to disable cookies, some features of our website may not function properly.

      </p>
      <h2>6. Changes to Cookies Policy</h2>
      <p>
      We reserve the right to modify or update this Cookies Policy at any time. Any changes will be effective immediately upon posting the revised policy on our website. We encourage you to review this policy periodically for updates.

      </p>
      <h2>7. Contact Us</h2>
      <p>
      If you have any questions or concerns about our use of cookies or this Cookies Policy, please contact us at contact@quickcashleicester.com.


      </p>
      <h2>GDPR Consent</h2>
      <p>
      By using our website and services, you consent to the collection, use, and processing of your personal information in accordance with this Privacy Policy and our Cookies Policy. You have the right to withdraw your consent at any time by contacting us or adjusting your browser settings to manage cookies.<br />
We respect your privacy rights and are committed to protecting your personal information. If you have any questions, concerns, or requests regarding your data privacy, please contact us at contact@quickcashleicester.com.


      </p>
      
    </div>
    <Footer />
    </>
  );
};

export default CookiesPage;
