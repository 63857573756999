import React, { useEffect } from "react";
import { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import './Support.css';


const Support = () => {


  const [selectedItem, setSelectedItem] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  

  const handleSubmit = (event) => {
    event.preventDefault();
    // You can add your logic to handle form submission here
    console.log('Form submitted:', { name, email, subject, message });
    // Clear form fields after submission
    setName('');
    setEmail('');
    setSubject('');
    setMessage('');
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };



  const renderContent = () => {
    if (selectedItem === 'Contact Us') {
      return (
        <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            {/* Sidebar content here */}
          </div>
          <div className="col-md-9">
            <div className=" " style={{ minHeight: '100vh', paddingTop: '10px' }}>
              <h2 className="text-center">Contact Us</h2>
              <form
                className="mx-auto"
                onSubmit={handleSubmit}
                style={{
                  background: '#f2f2f2',
                  padding: '20px',
                  borderRadius: '7px',
                  boxSizing: 'border-box',
                  maxWidth: '420px',
                  margin: '0 auto',
                }}
              >
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="subject">Subject</label>
                  <input
                    type="text"
                    className="form-control"
                    id="subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <textarea
                    className="form-control"
                    id="message"
                    rows="5"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  ></textarea>
                </div>
                <div className="form-group">
                  <label htmlFor="Visit Us">Visit Us</label>
                  <address>
                    6 HANNAM COURT,
                    CHARLES STREET,
                    LEICESTER
                    ENGLAND,
                    LE1 3FS
                  </address>
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      );
    } else if (selectedItem === 'Store Specific Help') {
      return (
        <div>
          <h2>Help with regards to our Stores</h2>
          <ul class="ml-3">
              <li>General Questions</li>
              <li>Product Questions</li>
              <li>Membership Requirements</li>
              <li>Miscellaneous Questions</li>
          </ul>
          <h3>General Questions</h3>
          <h5>How can I find my nearest store?</h5>
          <p>Head here and pop in your town or postcode into the search box under 'Find your nearest UK QCL store'.</p>
          <h5>Where do I find the opening hours?</h5>
          <p>Opening hours can be found at the bottom of each dedicated store page.</p>
          <h5>Can I return from one store to another store?</h5>
          <p>Of course. All our stores accept returns from other QCL stores.</p>
          <h5>How can I tell if the item I want is in my local QCL shop?</h5>
          <p>Type the name of your product in the search bar and refine your search by selecting your local store using ‘A specific store’s stock’ drop down box, under "By Availability" on the right of the page.</p>
          <p>The goods I want are not in my local store, how can I get them?QCL are online at webuy.com, so you can get what you want delivered right to your door.</p>
          <h5>Is the stock on the website accurate?</h5>
          <p>Yes it is! Pop down there before someone else gets their mitts on it.</p>
          <h5>How can I find out more about the goods I’m thinking of buying?</h5>
          <p>Check the product description of the item for more information.</p>
          <h5>What do I need to sell goods to a QCL?</h5>
          <p>Become a member, it's free & easy! Check out our Membership Requirements here</p>
          <h5>Where can I find the phone number for my local QCL store?</h5>
          <p>Unfortunately store phone numbers are for internal use only but please contact us with any questions you may have. If you are looking for live stock levels you can use our website.</p>
        </div>
      );
    }
    // } else if (selectedItem === 'Modern Slavery Statement') {
    //   return (
    //     <div>
    //       <h2>Modern Slavery Statement</h2>
    //       <h5>Introduction</h5>
    //       <p>This statement is made pursuant to section 54 of the Modern Slavery Act 2015. It constitutes QCL slavery and human trafficking statement for the financial year ended 31 December 2018.</p>
    //       <h5>Our Organization</h5>
    //       <p>QCL was founded in London in 1992. We have stores in the UK, Spain, Ireland, India, Australia, Portugal, Netherlands, Mexico, Poland and Canarias.</p>
    //       <p>We buy, sell and exchange a range of technology and entertainment products including mobile phones, video games, DVDs and Blu-ray movies, computers, digital electronics, TVs and monitors, and music CDs.</p>
    //       <p>We have more than 700 stores worldwide and we are growing.</p>
    //       <h5>Our Supply Chain</h5>
    //       <p>As a second hand trade company our supply chain is the customer. We do however work with third party companies who act as a support service to our needs.</p>
    //       <h5>Policies</h5>
    //       <p>https://dev-portal.QCL.webuy.dev/transfer-group-managerAs an expanding business, we are in the process of reviewing our policies in order to make sure that our third parties understand our expectations with regards to human rights across all areas of our supply chain.</p>
    //       <p>QCL actively encourages employees to report any activity that they believe is in breach of company human rights standards, for this we have a whistleblowing procedure in place to report any concerns about the way we and our third parties perform our tasks.</p>
    //       <h5>Due Diligence and Risk Assessment</h5>
    //       <p>QCL is fortunate that out supply is from the general public, but we still use support services to assist with the day to day running of our business. Many of these parties already have robust Due Diligence measures in place.</p>
    //       <p>As part of the ongoing improvement to QCL policies and standards, we are ensuring that we have a more comprehensive approach to risk assessment and identification of risk. In 2018 we made substantial improvements to our compliance department to assist in reviewing policies and procedures belonging to ourselves and to our third parties.</p>
    //       <p>Where applicable we visit our third parties to ensure their working conditions, policies and procedures are up to an excellent standard. Given the geographical location of some of our third parties, we are committed to understanding our relationships better by ensuring transparency with strong working contracts.</p>
    //       <h5>Measurement of Effectiveness</h5>
    //       <p>QCL will continue to monitor our third party support services both existing and new. Should any instances of non-compliance with the Act arise in relation to any of our third parties then this will be reviewed.</p>

    //     </div>
    //   );
    // } 
    else if (selectedItem === 'Help with Selling an item..') {
      return (
        <div>
          <h2>Help with Selling an item to QCL</h2>
          <ul class="ml-3">
              <li>General</li>
              <li>Order tracking</li>
              <li>Overdue Delivery</li>
              <li>Returns policy</li>
              <li>Cancelling an Order</li>
              <li>Products</li>
          </ul>
          <h4>General</h4>
          <h5>Are your products new or second hand?</h5>
          <p>All products are second hand unless stated. Hello good Karma.</p>
          <h5>Is there a warranty on items that I buy from QCL?</h5>
          <p>Yes, for your peace of mind, QCL offer a 24-month warranty* (on all items purchased on or after 14th Nov 2015) on all products excluding consumables.</p>
          <h5>Is there a limit to how much I can buy?</h5>
          <p>Yes, you can buy up to 2 of any 1 item, and your basket can have up to 50 items in total.</p>
          <h5>Are the quantities available to buy online live?</h5>
          <p>Yes, the stock and prices shown are live.</p>
        </div>
      );
    } else {
      return (
        <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            {/* Sidebar content here */}
          </div>
          <div className="col-md-9">
            <div className=" " style={{ minHeight: '100vh', paddingTop: '10px' }}>
              <h2 className="text-center">Contact Us</h2>
              <form
                className="mx-auto"
                onSubmit={handleSubmit}
                style={{
                  background: '#f2f2f2',
                  padding: '20px',
                  borderRadius: '7px',
                  boxSizing: 'border-box',
                  maxWidth: '420px',
                  margin: '0 auto',
                }}
              >
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="subject">Subject</label>
                  <input
                    type="text"
                    className="form-control"
                    id="subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <textarea
                    className="form-control"
                    id="message"
                    rows="5"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  ></textarea>
                </div>
                <div className="form-group">
                  <label htmlFor="Visit Us">Visit Us</label>
                  <address>
                    6 HANNAM COURT,
                    CHARLES STREET,
                    LEICESTER
                    ENGLAND,
                    LE1 3FS
                  </address>
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      );
    }
  };



                    
  

  return (
    <>
    
    <Navbar /><br /><br /><br /><br />
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-3 sidebar" style={{backgroundColor: '#071A44'}}>
          <h2 className="text-white">Support</h2>
          <ul className="nav flex-column">
            <li className="nav-item">
              <button className="nav-link text-white" onClick={() => handleItemClick('Store Specific Help')}>Store Specific Help</button>
            </li>
            {/* <li className="nav-item">
              <button className="nav-link text-white" onClick={() => handleItemClick('Modern Slavery Statement')}>Modern Slavery Statement</button>
            </li> */}
            <li className="nav-item">
              <button className="nav-link text-white" onClick={() => handleItemClick('Help with Selling an item..')}>Help with Selling an item..</button>
            </li>
            <li className="nav-item">
              <button className="nav-link text-white" onClick={() => handleItemClick('Contact Us')}>Contact Us</button>
            </li>
            
          </ul>
        </div>
        <div className="col-md-9 content">
          {renderContent()}
        </div>
      </div>
    </div>
    <Footer />
    </>
  )
}
export default Support;