import React, { useEffect, useState } from "react";
import "./Banar.css"
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import axios from "axios";
import Env from "../Environment/Env";
import { getAsset } from "../utils/helper";
import {
    TextField,
    List,
    ListItemButton,
    ListItemText,
    Popper,
  } from "@mui/material";

import {Paper, Grid, Card, CardContent} from '@mui/material'

const Banar = () => {

    const [searchText, setSearchText] = useState("");
    const [options, setOptions] = useState(["Choose Options"]);
  const [options1, setOptions1] = useState([]);
  const [options2, setOptions2] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleInputChange = (event) => {
        setSearchText(event.target.value.toLowerCase());
        setAnchorEl(event.currentTarget);
      };
    
      const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
      };











    const [searchData, setSearchData] = useState({});
    const [SubCatagoryData, setSubCatagoryData] = useState({});


    useEffect(()=>{
        getAllSubCatagories();
    },[]);

    const AllGetSearchData = (data) => {
        axios.get(`${Env.server}/api/product/getSingleProduct/${data}`)
            .then((res) => {
                // console.log("abc===>>>", res.data.message);
                console.log("abc===>>>", res.data.products);
                setSearchData(res.data.products)
            })
            .catch((err) => {
                console.log("err===>>>", err);
            })
    }

    const getAllSubCatagories = ()=>{
        axios.get(`${Env.server}/api/subcatagory/GetAllSubCatagory`)
    .then((res)=>{
        console.log("res===>>>", res.data.subcatagories);
        setSubCatagoryData(res.data.subcatagories)
    })
    .catch((err) => {
        console.log("err===>>>", err);
    })
}

    return (
        <>
        
            <Navbar/><br /><br />
            <section className="">
                <div className="row justify-content-center" >
                   
                    <div className="col-md-12 text-center pt-2 pl-0 pr-0">
                        <div class="container">
                            <img src="https://ik.imagekit.io/vhfsx9xkeh/khuram.png?updatedAt=1711560917315" alt="" class="imgslider" />
                        </div>

                        <div className="main mt-4 pb-4">
                                <div className="search-box pt-3 mb-3">
                                   {/*} <div className="dropdown"><br /><br />
                                        <input
                                            className="search-input rounded-pill text-center p-2"
                                            type="text"
                                            id="dropdownMenuButton"
                                            onChange={(e) => AllGetSearchData(e.target.value)}
                                            // data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            placeholder="What do you want to sell?"
                                        /> 
                                    </div>

                                    <div className="d-flex justify-content-end">
                                        <button
                                            className="search-btn p-2"
                                            style={{ marginTop: "50px", marginLeft: "310px" }}
                                        >
                                            <i className="bi bi-search fs-5"></i>
                                        </button>
                                    </div>*/}
                                <div className="container-fluid">
                                    {/* OLD SEARCH BAR */}
      {/* <div className="row justify-content-center">
        <div className="col-lg-10 col-md-12">
          <form className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
              aria-label="Search"
              aria-describedby="search-addon"
            />
            <button className="btn btn-outline-secondary" type="button" id="search-addon">
              <i className="bi bi-search"></i>
            </button>
          </form>
        </div>
      </div> */}

<div className="d-none d-lg-flex justify-content-center align-items-center flex-grow-1">
              <div className="input-group search-bar-lg">
                <input
                  type="text"
                  className="form-control"
                  placeholder="By device, brand or item"
                  value={searchText}
                  onChange={handleInputChange}
                  onClick={handleClick}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="bi bi-search"></i>
                  </span>
                </div>
              </div>
              <Popper
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                style={{ width: "400px" }}
              >
                <Paper
                  style={{
                    maxHeight: "250px",
                    overflowY: "auto",
                    width: "auto",
                  }}
                >
                  <List>
                    {/* Assuming options2, options1, and options are defined */}
                    <ListItemButton disabled>
                      <ListItemText primary="Accessories" />
                    </ListItemButton>
                    {options2
                      .filter((option) =>
                        option.toLowerCase().includes(searchText.toLowerCase())
                      )
                      .map((option, index) => (
                        <Link
                          to={`/AwmPageB/${option}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                          key={index}
                        >
                          <ListItemButton onClick={() => setSearchText(option)}>
                            <ListItemText primary={option} />
                          </ListItemButton>
                        </Link>
                      ))}
                    <ListItemButton disabled>
                      <ListItemText primary="Models" />
                    </ListItemButton>
                    {options1
                      .filter((option) =>
                        option.toLowerCase().includes(searchText.toLowerCase())
                      )
                      .map((option, index) => (
                        <Link
                          to={`/Model/brand/${option}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                          key={index}
                        >
                          <ListItemButton onClick={() => setSearchText(option)}>
                            <ListItemText primary={option} />
                          </ListItemButton>
                        </Link>
                      ))}
                    <ListItemButton disabled>
                      <ListItemText primary="Brand" />
                    </ListItemButton>
                    {options
                      .filter((option) =>
                        option.toLowerCase().includes(searchText.toLowerCase())
                      )
                      .map((option, index) => (
                        <Link
                          to={`/BrandPage/${option}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                          key={index}
                        >
                          <ListItemButton onClick={() => setSearchText(option)}>
                            <ListItemText primary={option} />
                          </ListItemButton>
                        </Link>
                      ))}
                  </List>
                </Paper>
              </Popper>
            </div>
                                </div>
                                </div> 
                                <div>
                                    <h5 className="text-white my-5">Or find your item from selected categories below</h5>
                                    <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                        {Array.isArray(SubCatagoryData) && SubCatagoryData.slice(0, 9).map((data, index) => (
                                            <Grid key={index} item lg={2} md={3} sm={4} xs={6} sx={{ margin: "0.5rem" }}>
                                                <Link to={`/Specification/${data.subcatagory}/${data._id}`} style={{ textDecoration: 'none', background: '#0000' }}>
                                                    <Card variant="outlined" sx={{width: '75%', height: '100%', borderRadius: 4, backgroundColor: '#0000' }} >
                                                        <CardContent sx={{ backgroundColor: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                            <img style={{height: '100px', marginBottom: '0.5rem' }} src={getAsset(data.imageSubCatagory[0])} className="img-fluid bordered-image border-0 w-100 bg-white" alt="" />
                                                            <h5 className="smatcard"><b>{data.subcatagory}</b></h5>
                                                        </CardContent>
                                                    </Card>
                                                </Link>
                                            </Grid>
                                        ))}
                                    </Grid>                               
                                </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Banar;


