
import './App.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Homepage/Homepage';
import Navbar from './Navbar/Navbar';
import Banar from './Banar/Banar';
import Footer from './Footer/Footer';
import Terms from './Footer/Terms';
import Policy from './Footer/Policy';
import Cookies from './Footer/Cookies';
import Singin from './formlogin&singup/singin';
import Singup from './formlogin&singup/singup';
import Slider from './Slider/Slider';
import Specification from './Specification/Specification';
import DashboardPurchases from './dashboardpages/DashboardPurchases';

 
import Slidercontent from './Slidercontent/Slidercontent';
import SlidercontentnNVADI from './Slidercontent/SidercontentnNVADI';
import Aboutus from './Aboutus/Aboutus';
import AboutQcl from './AboutQcl/AboutQcl';
import Support from './Support/Supt';
import Supports from './Supports/Supports';
// import Selectedfield from './Specification/Selectedfield';
import Order from './Ordersummary/Order';
import Filter from './Fiterpage/Fiter';
import UserDashboad from './Dashboard/UserDashboard';
import Verify from './Ordersummary/Verify';
function App() {
  return (
    <>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="Navbar" element={<Navbar />} />
          <Route path="Banar" element={<Banar />} />
          <Route path="Footer" element={<Footer />} />
          <Route path="Terms" element={<Terms />} />
          <Route path="Policy" element={<Policy />} />
          <Route path="Cookies" element={<Cookies />} />

          <Route path="Singin" element={<Singin />} />
          <Route path="Singup" element={<Singup />} />



          <Route path="/UserDashboad" element={<UserDashboad />} />
          <Route path="/dashboardPurchases" element={<DashboardPurchases />} />
 
          <Route path="Slider" element={<Slider />} />
          <Route path="Specification/:subcatagory/:subcatagoryid" element={<Specification />} />
          <Route path="Filter/:SCatagory" element={<Filter />} />
          {/* <Route path="Slidercontent" element={<Slidercontent />} /> */}
          <Route path="Slidercontent/:id" element={<SlidercontentnNVADI />} />
          <Route path="Aboutus" element={<Aboutus />} />
          <Route path="AboutQcl" element={<AboutQcl />} />
          <Route path="Support" element={<Support />} />
          <Route path="Supports" element={<Supports />} />
          <Route path="Cart" element={<Order />} /> 
          <Route path="verified/:id" element={<Verify />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
