import React, { useEffect , useState } from "react";
import Navbar from "../Navbar/Navbar"; 
import Sidebar from "../Dashboard/Sidebar/Sidebar";
import SaleCompletionCard from "./SaleCompletionCard";
import axios from "axios";
import Env from "../Environment/Env";
import { getAsset } from "../utils/helper";

const DashboardPurchases=()=>{

    const [AllProductUser, setAllProductUser] = useState([]);

useEffect(()=>{
getAllProduct();
},[])

    const getAllProduct=()=>{
        axios.get(`${Env.server}/api/order/getall`)
            .then((res) => { 
                console.log("abc===>>", res.data.orders);

                let orders = res.data.orders;
                const clientId = localStorage.getItem("id"); // Assuming localStorage.getItem("id") fetches the client ID

                // Filter orders based on clientid
                const filteredOrders = orders.filter(order => order.clientid === clientId);

                
                // Merge all products from filteredOrders into one array
                const allProducts = filteredOrders.flatMap(order => order.product);
                
                console.log("abcbcbcbbcbc=====", allProducts);
                setAllProductUser(allProducts);
            })
            .catch((err) => {
                console.log("err====>>>", err)
            })
    }


    return(
        <>
        
        <Navbar /><br /><br /><br />
        <div className="container-fluid">
                <div className="row-md-auto d-flex align-items-center">
                    {/* Sidebar takes up 4 columns */}
                    <div className="">
                        <Sidebar />
                    </div>
                    <div>
                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
                            {AllProductUser.map((data, index) => (
                                <div key={index} className="col">
                                    <div className="card" style={{ height: '100%', backgroundColor: "white", border: "1px solid grey" }}>
                                        <img src={getAsset(data.data && data.data.image[0])} className="card-img-top img-fluid" alt="Product Image" style={{ maxHeight: '150px', objectFit: 'contain' }} />
                                        <div className="card-body d-flex flex-column justify-content-between">
                                            <div>
                                                <h5 className="card-title"> Product: &nbsp;{data.data.name} </h5>
                                                <p className="card-text">Price: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{data.data.price}</p>
                                            </div>
                                            <button className="btn btn-primary mt-2">View Receipt</button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>

                </div>
        </div>        
        
        </>
    )
}
export default DashboardPurchases;
