import React, { useEffect, useState } from "react";
import './Navbar.css';
import { Link, useNavigate } from "react-router-dom";
import Env from "../Environment/Env";
import axios from "axios";
import userImage from './user.png';
import toggleImage from './toggle.png';


// import { Link } from "react-router-dom";
import Support from "../Support/Supt";

const Navbar = () => {

  const navigate = useNavigate();
  const [CheckUser, setCheckUser] = useState(false)
  const [UserPresent, setUserPresent] = useState(false)
  const [GetData, setGetData] = useState([])
  let check = localStorage.getItem("role");

  useEffect(() => {
    setUserPresent(!check ? false : true);
    setCheckUser(check === "admin" ? true : false);
  }, [check])

  useEffect(()=>{
    gettAllSScatagory();
  },[]);


  const gettAllSScatagory=()=>{
    axios.get(`${Env.server}/api/subsubcatagory/GetAllSubSubCatagory`)
    .then((res)=>{
      console.log("abc====", res.data.subsubcatagories);
      setGetData(res.data.subsubcatagories);
    })
    .catch((err) => {console.log("err====>>>", err)})
  }
  const mergeSubcategories = (subcategories) => {
    const mergedSubcategories = {};
    subcategories.forEach((subcategory) => {
      const { subcatagory, subsubcatagory } = subcategory;
      if (!mergedSubcategories[subcatagory]) {
        mergedSubcategories[subcatagory] = [subsubcatagory];
      } else {
        mergedSubcategories[subcatagory].push(subsubcatagory);
      }
    });
    return mergedSubcategories;
  };

  // Preprocess GetData to merge categories and subcategories
  const mergedCategories = {};
  GetData.forEach((data) => {
    const { catagory, subcatagory, subsubcatagory } = data;
    if (!mergedCategories[catagory]) {
      mergedCategories[catagory] = {};
    }
    if (!mergedCategories[catagory][subcatagory]) {
      mergedCategories[catagory][subcatagory] = [];
    }
    mergedCategories[catagory][subcatagory].push(subsubcatagory);
  });



  return (
    <>
       

      <div className="fixed-top">
        <nav className="navbar navbar-expand-md">
          <div className="container-fluid" style={{ height: "7vh" }}>
            <a  data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample"><img src={toggleImage} className="ml-2" alt="..." style={{ height: "4vh"}} /></a>
            <div style={{position:"absolute",right:"3%", bottom:"-2%"}} class="d-lg-none d-md-none ">
              <Link to="/" className="about-link uk">
                <img
                  src="https://ik.imagekit.io/b6iqka2sz/logo.png?updatedAt=1704468069338"
                  alt=""
                  className=""
                  style={{ height: "7vh", filter: "invert(100%)" }}
                />

              </Link>



              </div>
            <div className="collapse navbar-collapse " id="navbarText">
              <Link to="/" className="about-link uk">
                <img
                  src="https://ik.imagekit.io/b6iqka2sz/logo.png?updatedAt=1704468069338"
                  alt=""
                  className="ml-5 img-fluid"
                  style={{ height: "7vh", filter: "invert(100%)" }}
                />

              </Link>

              



              <div className="ml-auto text-center">
                <h1 style={{ fontSize: '1rem', color: 'white' }}>Quick Cash Leicester</h1>
              </div>
              <span className="navbar-text ml-auto">
              <div className="text-center mr-5 lb">
                  {!UserPresent ?
                    (
                      <Link to="/Singin" className="about-link text-decoration-none" >
                        <img src={userImage} alt="" style={{ height: "3vh" }} />
                        <h6 style={{ fontSize: '1rem', color: 'white' }}>Sign in</h6>
                      </Link>
                    ) : (
                      <>
                        {CheckUser === true ? (
                          <>
                            <a href={`${Env.dashboard}`} className="about-link text-decoration-none">
                              <img src="https://icons8.com/icon/101822/performance-macbook" alt="" width="30px" />
                              <h5 className="fs-6 text-white">Dashboard</h5>
                            </a>
                          </>
                        ) : (
                          <>
                            <Link to="/UserDashboad" className="about-link text-decoration-none">
                              <img src="https://ik.imagekit.io/b6iqka2sz/_%20icon%20_user_.png?updatedAt=1703450430632" alt="" width="30px" />
                              <h5 className="fs-6 text-white">Dashboard</h5>
                            </Link>
                          </>
                        )
                        }
                      </>
                    )
                  }
              </div>
              </span>
            </div>
          </div>
        </nav>
      </div>


      {/* --------------------------------------Sidebar------------------------------------ */}
    
      {/* <div className="offcanvas offcanvas-start " tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" style={{ borderBottomRightRadius: "50px", borderTopRightRadius: "50px" }}>
      
        <hr className="bg-black border-1" />

        <div className="offcanvas-body">
          <div>
            <h4 className="ml-2">Sell</h4>
          </div>


          <div className="dropdown">
            <Link to="/Filter" className="dropdown-text ml-2" style={{ fontSize: '15px', display: 'flex', alignItems: 'center', justifyContent: "space-between" }}> Gaming   <i className="bi bi-chevron-right icon-large ml-20"></i></Link>
          </div> 
          <div className="accordion my-5" id="categoryAccordion">
            {Object.entries(mergedCategories).map(([category, subcategories], index) => (
              <div className="accordion-item" key={index}>
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${index}`}
                    aria-expanded="true"
                    aria-controls={`collapse${index}`}
                  >
                    {category}  
                  </button>
                </h2>
                <div
                  id={`collapse${index}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`heading${index}`}
                  data-bs-parent="#categoryAccordion"
                >
                  <div className="accordion-body">
                    {Object.entries(subcategories).map(([subcategory, subsubcategories], subIndex) => (
                      <div className="accordion-item" key={subIndex}>
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#subcollapse${index}-${subIndex}`}
                            aria-expanded="false"
                            aria-controls={`subcollapse${index}-${subIndex}`}
                          >
                            {subcategory}  
                          </button>
                        </h2>
                        <div
                          id={`subcollapse${index}-${subIndex}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`subheading${index}-${subIndex}`}
                          data-bs-parent={`#collapse${index}`}
                        >
                          <div className="accordion-body">
                            <ul className="list-unstyled">
                              {subsubcategories.map((subsubcat, subsubIndex) => {
                               
                              return (
                                <span style={{marginTop:"10px"}}>
                                  <Link to={`/Filter/${subcategory}`}>
                                    <li style={{cursor:"pointer"}} key={subsubIndex}>{subsubcat}</li>
                                  </Link>
                                </span>
                              );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>

          
          
          <br />




          <hr className="bg-black border-1 mt-1" />
          <div className="dropdown mt-1">
            <Link to="/Support"  className="dropdown-text ml-2" style={{ fontSize: '15px' }} >
              Support
            </Link>

          </div>
          <div className="dropdown mt-5">
            <Link to="/Aboutus" className="dropdown-text ml-2" style={{ fontSize: '15px' }} >
              About Us
            </Link>
          </div>
          <div className="dropdown mt-5">
            <span data-bs-dismiss="offcanvas" aria-label="Close">
              <Link onClick={() => {
                localStorage.clear();
              }} className="dropdown-text ml-2" style={{ fontSize: '15px' }} >
                Logout
              </Link>
            </span>

          </div>

        </div>


      </div>  */}



    <div className="custom-offcanvas offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
      <hr className="bg-black border-1" />

      <div className="offcanvas-body">
        <div>
          <h4 className="ml-2">Sell</h4>
        </div>

        <div className="dropdown">
          <a href="/Filter" className="dropdown-text ml-2" style={{ fontSize: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>Gaming <i className="bi bi-chevron-right icon-large ml-20"></i></a>
        </div>

        <div className="accordion my-5" id="categoryAccordion">
          {Object.entries(mergedCategories).map(([category, subcategories], index) => (
            <div className="accordion-item" key={index}>
              <h2 className="accordion-header">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${index}`}
                  aria-expanded="true"
                  aria-controls={`collapse${index}`}
                >
                  {category}
                </button>
              </h2>
              <div
                id={`collapse${index}`}
                className="accordion-collapse collapse"
                aria-labelledby={`heading${index}`}
                data-bs-parent="#categoryAccordion"
              >
                <div className="accordion-body">
                  {Object.entries(subcategories).map(([subcategory, subsubcategories], subIndex) => (
                    <div className="accordion-item" key={subIndex}>
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#subcollapse${index}-${subIndex}`}
                          aria-expanded="false"
                          aria-controls={`subcollapse${index}-${subIndex}`}
                        >
                          {subcategory}
                        </button>
                      </h2>
                      <div
                        id={`subcollapse${index}-${subIndex}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`subheading${index}-${subIndex}`}
                        data-bs-parent={`#collapse${index}`}
                      >
                        <div className="accordion-body">
                          <ul className="list-unstyled">
                            {subsubcategories.map((subsubcat, subsubIndex) => (
                              <span style={{ marginTop: "10px" }}>
                                <a href={`/Filter/${subcategory}`}>
                                  <li style={{ cursor: "pointer" }} key={subsubIndex}>{subsubcat}</li>
                                </a>
                              </span>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>

        <br />

        <hr className="bg-black border-1 mt-1" />

        <div className="dropdown mt-1">
          <a href="/Support" className="dropdown-text ml-2" style={{ fontSize: '15px' }}>Support</a>
        </div>

        <div className="dropdown mt-5">
          <a href="/Aboutus" className="dropdown-text ml-2" style={{ fontSize: '15px' }}>About Us</a>
        </div>

        <div className="dropdown mt-5">
          <span data-bs-dismiss="offcanvas" aria-label="Close">
            <a onClick={() => { localStorage.clear(); }} className="dropdown-text ml-2" style={{ fontSize: '15px' }}>Logout</a>
          </span>
        </div>
      </div>
    </div>


      

      

      





      

    </>

  )

}
export default Navbar;