import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

const TermsAndConditionsPage = () => {
  return (
    <>
    <Navbar />
      <div style={{margin: '50px', padding: "20px" }}>
        <h1>Terms and Conditions</h1>
        <p>
          Welcome to Quick Cash Leicester (QCL). These Terms and Conditions
          govern your use of our website and services. By accessing or using our
          website, you agree to comply with these terms. Please read them
          carefully before proceeding.
        </p>
        <h2>1. Acceptance of Terms</h2>
        <p>
          By using the Quick Cash Leicester website or availing our services,
          you agree to be bound by these Terms and Conditions. If you do not
          agree with any part of these terms, you may not use our website or
          services.
        </p>
        <h2>2. Eligibility</h2>
        <p>
          You must be at least 18 years old to use our website and services. By
          using our website, you warrant that you are of legal age and have the
          authority to enter into binding contracts.
        </p>
        <h2>3. Services Offered</h2>
        <p>
          Quick Cash Leicester offers a service where customers can sell their
          mobile devices, gadgets, electronics, and game consoles in exchange
          for quick cash. We provide an efficient and convenient process for
          selling your items, including bank transfer or PayPal payment options.
        </p>
        <h2>4. Device Evaluation</h2>
        <p>
          When selling your device to Quick Cash Leicester, you agree to provide
          accurate information about the device's condition, specifications, and
          ownership. Our team will evaluate the device upon receipt to verify
          its condition and authenticity. We reserve the right to refuse any
          device that does not meet our criteria or appears to be fraudulent.
        </p>
        <h2>5. Pricing and Payment</h2>
        <p>
          The price offered for your device is based on various factors,
          including its condition, market value, and demand. We strive to
          provide fair and competitive prices for all devices. Payment will be
          made via bank transfer or PayPal, as per your preference, upon
          successful verification of the device.
        </p>
        <h2>6. Ownership and Transfer of Rights</h2>
        <p>
          By selling your device to Quick Cash Leicester, you agree to transfer
          ownership and all associated rights to us. Once payment is made, you
          relinquish any claims or rights to the device and its contents.
        </p>
        <h2>7. Privacy and Data Security</h2>
        <p>
          We respect your privacy and handle your personal information with the
          utmost care. Any information collected during the selling process will
          be used solely for the purpose of completing the transaction and will
          not be shared with third parties without your consent. Please refer to
          our Privacy Policy for more information.
        </p>
        <h2>8. Warranty and Liability</h2>
        <p>
          Quick Cash Leicester does not provide any warranty or guarantee for
          devices purchased through our service. All devices are sold "as is,"
          and we are not liable for any defects or issues that may arise after
          the sale. We recommend backing up your data and performing a factory
          reset before selling your device.
        </p>
        <h2>9. Dispute Resolution</h2>
        <p>
          In the event of any disputes or disagreements regarding the sale of
          your device, we encourage you to contact us directly to resolve the
          issue amicably. If a resolution cannot be reached, you may seek legal
          recourse in accordance with applicable laws.
        </p>
        <h2>10. Modifications to Terms</h2>
        <p>
          Quick Cash Leicester reserves the right to modify or update these
          Terms and Conditions at any time without prior notice. It is your
          responsibility to review these terms periodically for any changes.
          Continued use of our website or services constitutes acceptance of the
          revised terms.
        </p>
        <h2>11. Contact Us</h2>
        <p>
          If you have any questions or concerns about these Terms and
          Conditions, please contact us at contact@quickcashleicester.com.
          <br />
          <br />
          Thank you for choosing Quick Cash Leicester. We look forward to
          serving you!
        </p>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditionsPage;
