import React, { useEffect , useState } from "react";
import './slidercontetnNVADIA.css';
import Navbar from "../Navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Env from "../Environment/Env"
import { getAsset } from "../utils/helper";


const SlidercontentnNVADI=()=>{
    let navigate = useNavigate();
    const {id} = useParams();
 
    console.log("abc=======", id);
    const [SingleproductData , setSingleProductData] = useState({});
    const [ClickId, setClickId] = useState("");
    const [QuantityFinal, setQuantityFinal] = useState(1); // State variable to hold quantity


    useEffect(()=>{
        GetProductData(); 
    },[])

    const GetProductData=()=>{
        axios.get(`${Env.server}/api/product/getSingleProductbyid/${id}`)
        .then((res) => {
            console.log("res====>>>", res.data.products);
            setSingleProductData(res.data.products)
        })
        .catch((err) => {
            console.log("err====>>>", err);
        })
    }


    const handleSellProduct = (data, id, quan) => {
        console.log("quanquanquan....", quan)
        if (quan === 0 || quan === undefined) {
            quan = 1
        }
        console.log("quanquanquan....", quan)

        const existingData = JSON.parse(localStorage.getItem('productData')) || []; // Step 1: Retrieve existing data or initialize as an empty array
        const newData = { data: data, quantity: quan }; // Create new data object

        // Find if the product already exists in the existing data
        const existingIndex = existingData.findIndex(item => item.data._id === id);

        if (existingIndex !== -1) {
            // If product exists, update its quantity
            existingData[existingIndex].quantity = quan;
        } else {
            // If product doesn't exist, add it to the data array
            existingData.push(newData);
        }

        // Step 3: Save the updated data back to the localStorage
        localStorage.setItem('productData', JSON.stringify(existingData));

        setQuantityFinal(quan);
        setClickId(id);

        console.log("abcmuzamal======", data);
    }



    return(
        <>
        <Navbar/><br/><br/><br/>
       
       
        <div class="container" style={{background:"#FFFFFF", marginTop: '14rem'}}> 
            <div class=" row text-white py-5 d-flex justify-content-between p-4 ">
                {/* <!-- Just Mobile Pic --> */}
                <div class=" col-md-4 mobile-pic p-5  ">
                    <img src={getAsset(SingleproductData.image && SingleproductData.image[0])} width="250px"className="img-fluid"/>
                </div>
                {/* <!-- Specifications --> */}
                <div class=" col-md-6 mobile-specs mt-5 me-5">
                    <p class="small" style={{color:"#071A44"}}> </p>
                                <h2 style={{ color: "#071A44" }}>{SingleproductData.name}</h2> 
                    <p class="my-3 fw-bold" style={{color: "#071A44"}}>More Details:</p>
                    <div class="cash d-flex justify-content-between" style={{color:"#071A44"}}>
                        <p class="leads">Cash</p>
                                    <p class="leads">£{SingleproductData.price}</p>
                    </div>
                                
                   
                </div>


            </div>
            <hr style={{color:"#071A44"}}/>

            <div class="color" style={{color:"#071A44"}}>

                <div class="product-detail-sepcification">
                    <h4 class="my-5 fw-bold leads">Product Details</h4>
                    <h4 class="specs">Specification</h4>
                    <ul class="mt-3">
                                    <li> <span class="fw-bold">Model Name: &nbsp;:</span> &nbsp; {SingleproductData.options && SingleproductData.options.ModelName && SingleproductData.options.ModelName[0]}</li>
                                    <li> <span class="fw-bold">Edition: &nbsp;:</span> &nbsp; {SingleproductData.options && SingleproductData.options.Edition && SingleproductData.options.Edition[0]}</li>
                                    <li> <span class="fw-bold">Storage: &nbsp;:</span>  &nbsp; {SingleproductData.options && SingleproductData.options.Storage && SingleproductData.options.Storage[0]}</li>
                                    <li> <span class="fw-bold">Condition: &nbsp;:</span> &nbsp; {SingleproductData.options && SingleproductData.options.Condition && SingleproductData.options.Condition[0]}  </li>
                    </ul>
                </div>

                            <button style={{ marginLeft: "40px" }} onClick={() => {
                                handleSellProduct(SingleproductData, SingleproductData._id, 1);
                                navigate("/Cart")
                            }} className="btn btn-primary">&nbsp;&nbsp;Sell Now&nbsp;&nbsp;</button>

            </div>
        </div>
        <br/><br/>
   
        </>
    )
}
export default SlidercontentnNVADI;