import React from "react";
import { useEffect } from "react";
import "./Aboutus.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

const Aboutus = () => {
  // handleSupportButtonClick()

  useEffect(() => {
    // handleSupportButtonClick()

    class Menu {
      //Constructor for Menu class
      constructor(_name, _activateID, _fa) {
        this.name = _name;
        this.activateID = _activateID;
        this.fa = _fa;
      }
      //Return an HTML element representation of the Menu
      GetHTMLElement = function () {
        var element = document.createElement("div");
        element.className = "menu";
        var p = document.createElement("p");

        var iT = document.createElement("i");
        iT.className = this.fa;
        p.appendChild(iT);

        p.innerHTML += " " + this.name;
        var i = document.createElement("i");
        i.className = "fa-solid fa-arrow-right";
        element.appendChild(p);
        element.appendChild(i);
        return element;
      };
    }

    //Define arrays for menus and pages
    var menus = [];
    var pages = [];

    //function for initializing, populating menu and page arrays
    function populateMenus() {
      menus.push(new Menu("About Us", "/"));
      menus.push(new Menu("Our Mission"));
      menus.push(new Menu("Why Choose Us"));
      menus.push(new Menu("How It Works"));

      menus.push(new Menu("What is QCL?"));
      menus.push(new Menu("Sell Tech", "/info/about"));
      menus.push(new Menu("Sell Games and Consoles"));
      // menus.push(new Menu("QCL Logos"));
      // menus.push(new Menu("Gender Pay Gap"));
      // menus.push(new Menu("Modern Slavery Statement"));
      // menus.push(new Menu("Our COVID-19 Response"));
      // menus.push(new Menu("Counterfeit Products Policy"));

      pages.push(document.getElementById("home"));
      pages.push(document.getElementById("settings"));
      pages.push(document.getElementById("about"));
      pages.push(document.getElementById("CeXHistory"));

      pages.push(document.getElementById("QCL"));
      pages.push(document.getElementById("SellTech"));
      pages.push(document.getElementById("SellGames"));
      // pages.push(document.getElementById("CeXLogos"));
      // pages.push(document.getElementById("GenderPayGap"));
      // pages.push(document.getElementById("ModernSlaveryStatement"));
      // pages.push(document.getElementById("OurCOVID-19Response"));
      // pages.push(document.getElementById("CounterfeitProductsPolicy"));
    }

    //Call functions and open first page
    populateMenus();
    displayUpdatedMenus();
    open(0);

    //update and render menus
    function displayUpdatedMenus() {
      var sidebarElement = document.getElementById("sidebar");
      var sidebarMenus = sidebarElement.querySelectorAll(":scope > .menu");
      for (let i = 0; i < sidebarMenus.length; i++) {
        sidebarMenus[i].remove();
      }
      for (let i = 0; i < menus.length; i++) {
        var element = menus[i].GetHTMLElement();
        sidebarElement.appendChild(element);
        element.addEventListener("click", function () {
          open(i);
        });
      }
    }

    //function for opening page on menu click
    function open(index) {
      for (let i = 0; i < pages.length; i++) {
        pages[i].style.display = "none";
      }
      pages[index].style.display = "block";
    }
  }, []);

  //  useEffect(()=>{
  //   let a = 0;
  //   if(a>1){
  // a++;
  // console.log("abc=====1", a);

  //   }else if(a === 0){
  //     window.location.reload();
  //     a++;
  //     console.log("abc=====2", a);
  //   }else{
  //     a++;
  //     console.log("abc=====3", a);

  //   }
  //  },[])

  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      {/* */}

      <section style={{ background: "#071A44" }}>
        <div class="allpage  ">
          <div id="sidebar" class="sidebar  ">
            <h1
              class="title "
              style={{ background: "#071A44", color: "#FFFFFF" }}
            >
              About Us
            </h1>
            {/* <li className="nav-item ml-4 mb-1">
              <button className="nav-link text-black bg-white rounded p-2" onClick={handleSupportButtonClick}>Refreash</button>
            </li> */}
          </div>
          {/* <!-- ------------------------------About Us--------------------- --> */}
          <div id="home" class="page ">
            <div class="content-wrapper">
              <h1 style={{ color: "#FFFFFF" }}>About Us</h1>
            </div>
            <br />
            <br />
            <p class="paragraph">
              Welcome to Quick Cash Leicester, your premier destination for
              turning your unused electronics into instant cash. We specialize
              in providing a seamless experience for selling and exchanging a
              variety of items, including games, phones, consoles, laptops,
              tablets, computers, and other electronic gadgets.
              <br /><br />
              At Quick Cash Leicester, we understand the importance of
              convenience and efficiency when it comes to selling your
              electronics. Our team of expert appraisers ensures that you
              receive the best value for your items, and our streamlined process
              ensures that you get paid quickly and securely. Whether you're
              upgrading to the latest model or simply looking to declutter your
              space, we make selling your electronics a breeze.
              <br /><br />
              But our commitment to our customers goes beyond just providing
              cash for your items. We strive to create a welcoming and
              supportive environment where you can feel confident and empowered
              throughout the selling process. Our knowledgeable staff is always
              on hand to answer any questions you may have and to provide
              guidance and assistance every step of the way.
              <br /><br />
              In addition to our buying services, Quick Cash Leicester also
              offers a range of other services to meet your needs. Whether
              you're looking to purchase quality refurbished electronics at
              affordable prices, explore our selection of accessories and
              peripherals, or take advantage of our repair services, we have you
              covered.
              <br /><br />
              Located in the heart of Leicester, Quick Cash Leicester is proud
              to serve the local community. With a commitment to exceptional
              service, fair prices, and a seamless experience, we aim to be your
              trusted partner for all your electronic needs. Visit us today and
              experience the Quick Cash Leicester difference for yourself!
            </p>
          </div>
          {/* <!-- -------------------------------Our Mission------------------------ --> */}

          <div id="settings" class="page">
            <div class="content-wrapper">
              <h1 style={{ color: "#FFFFFF" }}>Our Mission</h1>
            </div>
            <br />
            <br />
            <p class="paragraph">
              At Quick Cash Leicester, our mission is driven by the desire to
              simplify the process of decluttering homes while providing a swift
              financial solution. We understand that many individuals have
              valuable tech items lying unused, and we aim to offer a
              hassle-free platform for them to convert these items into instant
              cash.
              <br /><br />
              Our commitment extends beyond just facilitating transactions; we
              prioritize fairness and transparency in every interaction. We
              strive to ensure that our customers receive the best value for
              their belongings, and we accomplish this through a rigorous
              evaluation process and competitive pricing.
              <br /><br />
              Furthermore, we aim to foster trust and reliability in our
              services. By maintaining clear communication and adhering to
              ethical business practices, we aim to become the go-to destination
              for individuals in Leicester seeking to sell their electronics
              quickly and securely.
            </p>
            {/* <div>
              
            <div class="">
              <div class="row">
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/slider/iphone_13_2-removebg-preview.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">iPHONE Xs Max</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/slider/iphone_13_2-removebg-preview.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">iPHONE X</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/slider/iphone_13_2-removebg-preview.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">iPHONE 13 Pro Max</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/slider/iphone_13_2-removebg-preview.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">iPHONE 12 Pro Max</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
<br /><br />
              
            <div class="">
              <div class="row">
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/slider/iphone_13_2-removebg-preview.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">iPHONE 7</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/slider/iphone_13_2-removebg-preview.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">iPHONE 6</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/slider/iphone_13_2-removebg-preview.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">iPHONE 8</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/slider/iphone_13_2-removebg-preview.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">iPHONE 6s</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div> */}
            {/* <h4 class="pt-4" className="fs-5 mt-5 mb-2" style={{ color: "#FFFFFF" }}>Join over 2 Million QCL App users today!</h4> */}
            {/* <div class="row">
              <div class="col-12 col-md-4">
                <button type="button" class="btn btn-lg p-3 ml-4 pt-2 w-100 w-md-25" style={{ background: "#FFFFFF", color: "#071A44" }}>All other iPhones</button>
              </div>
            </div> */}
          </div>

          {/* <!-- --------------------------------Why Choose Us------------------------ --> */}
          <div id="about" class="page">
            <div class="content-wrapper">
              <h1 style={{ color: "#FFFFFF" }}>
              "Why Choose Us"
              </h1>
            </div>
            <br />
            <br />
      <span style={{fontWeight: 'bold', color: 'white'}}>Instant Cash: </span><p style={{color: 'white'}}>At Quick Cash Leicester, we offer immediate payment for your electronics and gadgets, providing you with fast access to cash when you need it most.</p><br />
      <span style={{fontWeight: 'bold', color: 'white'}}>Hassle-Free Process: </span><p style={{color: 'white'}}>Our streamlined selling process ensures that you can turn your unused items into money with minimal effort. We handle all the paperwork and logistics, making it convenient for you to sell your electronics.</p><br />
      <span style={{fontWeight: 'bold', color: 'white'}}>Fair Evaluation: </span><p style={{color: 'white'}}>We believe in offering fair prices for your tech items. Our experienced team conducts thorough assessments to ensure that you receive a competitive offer based on the condition and market value of your belongings.</p><br />
      <span style={{fontWeight: 'bold', color: 'white'}}>Transparency: </span><p style={{color: 'white'}}>Transparency is at the core of our business. We provide clear and upfront information about our evaluation criteria and pricing, ensuring that you know exactly what to expect throughout the selling process.</p><br />
      <span style={{fontWeight: 'bold', color: 'white'}}>Trustworthy Service: </span><p style={{color: 'white'}}>With Quick Cash Leicester, you can trust that your transactions are handled professionally and securely. We prioritize customer satisfaction and strive to exceed your expectations with every interaction.</p><br />
      <span style={{fontWeight: 'bold', color: 'white'}}>Local Convenience: </span><p style={{color: 'white'}}>As a Leicester-based business, we are conveniently located to serve the local community. Whether you're in the city center or surrounding areas, you can easily access our services and turn your electronics into cash without hassle.</p><br />
      <span style={{fontWeight: 'bold', color: 'white'}}>Reliable Reputation: </span><p style={{color: 'white'}}>Backed by a solid reputation in the industry, Quick Cash Leicester is known for its integrity, reliability, and commitment to customer satisfaction. Join the countless individuals who have chosen us for their selling needs and experience the difference for yourself.</p><br />

          
            {/* <div class="">
              <div class="row">
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/laptop/1.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">Apple Laptop</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/laptop/2.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">Apple Desktop</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/laptop/3.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">Apple Headphones</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/laptop/4.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">Camera Lenses</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <!-- --------------------------------second card----------------------- --> */}
            {/* <div class="">
              <div class="row">
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/laptop/1.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">Apple Laptop</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/laptop/2.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">Apple Desktop</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 ">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/laptop/3.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">Apple Headphones</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/laptop/4.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">Camera Lenses</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h4 class="pt-4" className="fs-5 mt-5 mb-2" style={{ color: "#FFFFFF" }}>Join over 2 Million QCL App users today!</h4>
            <div class="row">
              <div class="col-12 col-md-4">
                <button type="button" class="btn btn-lg p-3 ml-4 pt-2 w-100 w-md-25" style={{ background: "#FFFFFF", color: "#071A44" }} >All other CASH</button>
              </div>
            </div> */}
          </div>

          {/* <!-- ------------------------------------How It Works------------------------------- --> */}
          <div id="CeXHistory" class="page">
            <h1 style={{ color: "#FFFFFF" }}>How It Works</h1>
            <h4 style={{ color: "#FFFFFF" }}>Submit Your Items:</h4>
            <p style={{ color: "#FFFFFF" }}>
              Begin by bringing your unwanted electronics and gadgets to our
              Quick Cash Leicester store. Our friendly staff will assist you in
              evaluating your items and determining their eligibility for sale.
            </p>
            <h4 style={{ color: "#FFFFFF" }}>Evaluation Process:</h4>
            <p style={{ color: "#FFFFFF" }}>
              Once you've submitted your items, our experienced team will
              conduct a thorough assessment to determine their condition and
              market value. We consider factors such as functionality, age, and
              overall condition to provide you with a fair and competitive
              offer.
            </p>
            <h4 style={{ color: "#FFFFFF" }}>Receive a Quote:</h4>
            <p style={{ color: "#FFFFFF" }}>
              After evaluating your items, we'll provide you with a quote based
              on their assessed value. You'll receive a transparent and upfront
              offer, ensuring that you know exactly what to expect for your
              electronics.
            </p>
            <h4 style={{ color: "#FFFFFF" }}>Accept the Offer:</h4>
            <p style={{ color: "#FFFFFF" }}>
              If you're satisfied with the offer presented, simply accept it to
              proceed with the selling process. Our team will guide you through
              the necessary paperwork and payment procedures to ensure a smooth
              transaction.
            </p>
            <h4 style={{ color: "#FFFFFF" }}>Get Paid Instantly:</h4>
            <p style={{ color: "#FFFFFF" }}>
              Once the paperwork is complete and the offer is accepted, you'll
              receive instant payment for your items. We offer various payment
              options, including cash, bank transfer, or PayPal, providing you
              with flexibility and convenience.
            </p>
            <h4 style={{ color: "#FFFFFF" }}>Leave with Cash:</h4>
            <p style={{ color: "#FFFFFF" }}>
              With the transaction completed, you can leave our store with cash
              in hand, ready to use however you please. It's that simple and
              hassle-free to turn your unwanted electronics into quick cash at
              Quick Cash Leicester.
            </p>
            <h4 style={{ color: "#FFFFFF" }}>Enjoy Your Experience:</h4>
            <p style={{ color: "#FFFFFF" }}>
              Our goal is to make the selling process as seamless and enjoyable
              as possible for our customers. From start to finish, we prioritize
              your satisfaction and aim to exceed your expectations with our
              service and expertise.
            </p>

            <br />
            <br />
            {/* <h3 class="text-white">Bring Your Items:</h3>
            <p class="paragraph">
            Gather your unwanted electronics and bring them to our store.
            </p>
            <h3 class="text-white">Expert Appraisal:</h3>
            <p class="paragraph">
            Our experienced team will assess the condition and value of your items.
            </p>
            <h3 class="text-white">Get Instant Cash:</h3>
            <p class="paragraph">
            Once the appraisal is complete, you'll receive instant cash for your items.
            </p>
            <br />
            <p class="paragraph">Join countless satisfied customers who have turned their old gadgets into 
            cash with Quick Cash Leicester. Discover the ease of decluttering and the 
            satisfaction of walking away with extra money in your pocket. Visit us today and 
            experience the quickest way to turn your technology into cash.</p> */}
          </div>

          {/* <!-- -------------------------------QCLPeople----------------------- --> */}
          {/* <div id="QCLPeople" class="page">
            <h1 style={{ color: "#FFFFFF" }}> QCL People</h1>
            <div class="product-desc" style={{ color: "#FFFFFF" }}>
              <p class="leads">Have you fallen desperately and deeply in love with a QCL employee and want to put a name to the face? Don't worry, it happens all the time*. Use this section find contact details for people in all parts of the QCL empire.
                *Not strictly true.</p>
              <h4 class="fw-bold">QCL Executive Board !!!!!</h4>
              <p class="leads">Oooh. These are the big, scary bosses. They don't have sandwiches for lunch like the rest of us, they eat raw quail eggs and caviar. They don't drink beer, they quaff Chateau Rothschild 1996. If they went to the toilet - which they don't, 'cos they're royal - they'd use £50 notes instead of Andrex.</p>
              <p class="leads">If you want to spot them when they're out and about, just look for the teams of personal servants, specifically employed to scatter rose petals in front of them wherever they go (a word of warning, though: this can get a bit embarrasing if you ever have to share a tube carriage with one of them).</p>

              <h4 class="fw-bold">QCL Commercial & Strategy</h4>
              <p class="leads">Not only do these clever souls get hold of all those lovely new games, hardware and DVDs that we sell, they are also responsible for making sure we stay the cheapest, most value-laden place to buy your stuff. Hurrah for them, then.</p>

              <h4 class="fw-bold">
                Customer Service</h4>
              <p class="leads">These courteous individuals handle all customer enquiries to QCL. From Ballymena to Wolverhampton, they will deal with all your questions; Sales, Trade-ins, the whole lot. [Go on, give us a shout.](/site/support).</p>

              <h4 class="fw-bold">E-Commerce</h4>
              <p class="leads">Don't live near QCL, too lazy to travel to one of our stores? No problem! [Get in touch](/site/support), let them do the dirty work for you.</p>

              <h4 class="fw-bold">QCL Accounts</h4>
              <p class="leads">These are the people who hand out our wages, so they are officially the Best People In The World Ever. They also make lots of risque jokes about double-entry... Accountants find that sort of thing funny, apparently.</p>

              <h4 class="fw-bold">QCL HR</h4>
              <p class="leads">Fancy working for the funkiest videogames and PC retailer in the whole wide world? These are the people to talk to.</p>

              <h4 class="fw-bold">QCL IT</h4>
              <p class="leads">
                Made up from a team of highly evolved beings, the QCL IT team is capable of breaking - and then fixing - computers in ways that mere mortal minds can't even comprehend. If that fails, there's always turning it off and back on again.</p>

              <h4 class="fw-bold">QCL Prevention</h4>
              <p class="leads">This is our superhero team of health and safety checkers and crime fighters. If anyone is ever daft enough to try and pilfer something from our stores, it'll be QCL Prevention's hands on their shoulder. The would-be criminal will then be subjected to a five-hour lecture on the importance of workplace safety. So, remember kids... crime doesn't pay!</p>
            </div>

          </div> */}
          {/* <!-- ----------------------------PressClippings---------------------------- --> */}
          {/* <div id="PressClippings" class="page">
            <h1 style={{ color: "#FFFFFF" }}>Press Clippings</h1>
            <div class="card mb-3 ml-5" style={{ maxWidth: "1000px", background: "#071A44" }}>
              <div class="row g-0">
                <div class="col-md-4">
                  <img src="/image/press clipping/1.png" class="img-fluid rounded-start p-3" style={{ width: "500px" }} alt="..." />
                </div>

                <div class="col-md-8">
                  <div class="card-body ">
                    <h5 class="card-title pt-5 font-weight-bold text-white">QCL Features on BBC's 'Sort Your Life Out' with Stacey Solomon</h5>
                    <p class="card-text mi-3 text-white">Friday, 7 Jul 2023</p>
                    <p class="card-text text-white">.‣ QCL Features on BBC's 'Sort Your Life Out' with Stacey Solomon ...</p>
                    <button type="button" class="btn  rounded-pill " style={{ background: "#FFFFFF", color: "#071A44" }}>Read More</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="card mb-3 ml-5" style={{ maxWidth: "1000px", background: "#071A44" }}>
              <div class="row g-0">
                <div class="col-md-4">
                  <img src="/image/press clipping/2.png" class="img-fluid rounded-start p-3" style={{ width: "500px" }} alt="..." />
                </div>

                <div class="col-md-8">
                  <div class="card-body ">
                    <h5 class="card-title pt-5 font-weight-bold text-white">QCL Features on BBC's 'Sort Your Life Out' with Stacey Solomon</h5>
                    <p class="card-text mi-3 text-white">Friday, 7 Jul 2023</p>
                    <p class="card-text text-white">.‣ QCL Features on BBC's 'Sort Your Life Out' with Stacey Solomon ...</p>
                    <button type="button" class="btn  rounded-pill " style={{ background: "#FFFFFF", color: "#071A44" }}>Read More</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="card mb-3 ml-5" style={{ maxWidth: "1000px", background: "#071A44" }}>
              <div class="row g-0">
                <div class="col-md-4">
                  <img src="/image/press clipping/3.png" class="img-fluid rounded-start p-3" style={{ width: "500px" }} alt="..." />
                </div>

                <div class="col-md-8">
                  <div class="card-body ">
                    <h5 class="card-title pt-5 font-weight-bold text-white">QCL Features on BBC's 'Sort Your Life Out' with Stacey Solomon</h5>
                    <p class="card-text mi-3 text-white">Friday, 7 Jul 2023</p>
                    <p class="card-text text-white">.‣ QCL Features on BBC's 'Sort Your Life Out' with Stacey Solomon ...</p>
                    <button type="button" class="btn  rounded-pill " style={{ background: "#FFFFFF", color: "#071A44" }}>Read More</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="card mb-3 ml-5" style={{ maxWidth: "1000px", background: "#071A44" }}>
              <div class="row g-0">
                <div class="col-md-4">
                  <img src="/image/press clipping/4.png" class="img-fluid rounded-start p-3" style={{ width: "500px" }} alt="..." />
                </div>

                <div class="col-md-8">
                  <div class="card-body ">
                    <h5 class="card-title pt-5 font-weight-bold text-white">QCL Features on BBC's 'Sort Your Life Out' with Stacey Solomon</h5>
                    <p class="card-text mi-3 text-white">Friday, 7 Jul 2023</p>
                    <p class="card-text text-white">.‣ QCL Features on BBC's 'Sort Your Life Out' with Stacey Solomon ...</p>
                    <button type="button" class="btn  rounded-pill" style={{ background: "#FFFFFF", color: "#071A44" }}>Read More</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12 col-md-8 text-center">
                  <button type="button" class="btn btn-lg btn-primary position-relative font-weight-bold  p-2 mb-3 ml-5 " style={{ maxWidth: "1000px", background: "#FFFFFF", color: "#071A44" }}>
                    Older Posts
                    <span class="d-none d-md-inline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <i class="bi bi-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>





          </div> */}
          {/* <!-- -----------------------------AboutPaul------------------------------ --> */}
          {/* <div id="AboutPaul" class="page">
            <h1 style={{ color: "#FFFFFF" }}>About Paul</h1>
            <p class="paragraph ">Paul Farrington, an experienced diver and one of the Computer Exchange's founding directors, was listed as missing after exploring an underwater wreck near Mullion Island, Cornwall on Monday (1 May 2000).</p>
            <p class="paragraph ">Paul imbued the Computer Exchange with his spirit. At its inception in 1992 he played a central role in creating its philosophy of free trade in goods for all. His enlightened understanding of human nature and personal warmth informed us all. He will be sorely missed by his many friends at the Computer Exchange.</p>
            <p class="paragraph">Paul's extraordinary career and farsighted approach to technology and the market place helped us develop our unique way of doing business. As a music journalist, technological pioneer and diver, Paul approached everything with a profound enthusiasm.</p>
            <p class="paragraph">The Computer Exchange is with his family and many friends in this hour, and offers them all the support at our disposal.</p>
            <p class="paragraph">It has come to our attention that some of the press reports regarding Paul have been inaccurate. To clarify issues;</p>
            <ul class="ml-3" style={{ color: "#FFFFFF" }}>
              <li>Paul Farrington is English and was born on 15/2/61.</li>
              <li>Paul Farrington was a founding Director of Computer Exchange with Robert Dudani</li>
              <li>Paul Farrington was highly regarded within both the computer and the video game industries</li>
              <li>He was not married and has no children</li>
              <li>He gained a Degree in Philosophy</li>
              <li>Paul Farrington lived in Camden Town, north London</li>
            </ul>
            <p class="paragraph">Many thanks to NTK, the weekly IT newsletter, for the following tribute to Paul</p>
            <p class="paragraph">Paul Farrington, co-founder of the QCL, the Computer Exchange in Tottenham Court Road, disappeared while exploring an underwater wreck off the coast of Cornwall last Tuesday. Anyone who has spent any time at QCL (and especially those who spent the bulk of their teenage years hanging out there) might have guessed that its inspiration came from more than the usual flogging of dodgy second-hand kit</p>
            <p class="paragraph">The compulsory smoking rules, the bizarrely knowledgeable staff, the eclectic (and tympanum-crunchingly loud) musical choices: Judging from the messages of shock and love we've heard, Paul was a great and unique part of the QCL ambience. "He was a surrogate father to a generation of geeks", said one correspondent. Charlie Brooker writes:</p>
            <p class="paragraph">One of my enduring memories of Paul is the day I first met him; I'd turned up for my first day at work in the computer department of Music and Video Exchange in Notting Hill, and walked in to find nobody there. I sat behind the counter for about half an hour, not really knowing what to do, and was about to leave when a tall, gangly man wearing pink tights and a fcking fez* stalked in, looked confused for a while, and then asked me who I was and what I was doing there. I really wasn't sure whether to run away or not. I'm glad I didn't. Once you got used to the fact he dressed like a maniac (and I particularly remember an incredible haircut he once had which made him look like a cross between Coolio and an exploding picnic basket), he became an incredibly likeable, inventive, anarchic, and weirdly trustworthy companion. I always looked up to him, even when he convinced me to shave all my hair off apart from one off-centre tuft, and I spent an entire month watching strangers cross the street when they saw me coming.</p>
            <p class="paragraph">Probably the simplest thing I could say about him is this: that he was easily the coolest person I've ever met, and my head refuses to believe he's gone.</p>



          </div> */}
          {/* <!-- ----------------------------------Cexlogos----------------------- --> */}
          {/* <div id="CeXLogos" class="page">
            <h1 style={{ color: "#FFFFFF" }}>QCL Logos</h1>
            <div class="card mb-3 ml-5" style={{ maxWidth: "1000px", background: "#071A44" }}>
              <div class="row g-0">
                <div class="col-md-12 ml-5 ml-5-sm">
                  <h6 class="card-title font-weight-bold pt-2 text-white">QCL Logo Rich black CMYK-01.png</h6>
                  <div class="d-flex align-items-center">
                    <img src="/image/logo.png" class="img-fluid rounded-start" style={{ width: "200px" }} alt="..." />
                    <div class="d-flex kl" style={{ background: "#071A44", color: "#FFFFFF" }}>
                      <button type="button" class="btn btn-lg me-2 rounded" style={{ background: "#FFFFFF", color: "#071A44" }}>Read More</button>&nbsp;&nbsp;&nbsp;&nbsp;
                      <button type="button" class="btn btn-lg rounded" style={{ background: "#FFFFFF", color: "#071A44" }}>Download</button>
                    </div>
                  </div>
                  <p class="card-text pt-2 text-white">For print CMYK Rich blacks- unsuitable for newsprint</p>
                </div>
              </div>
            </div>

            <div class="card mb-3 ml-5" style={{ maxWidth: "1000px", background: "#071A44" }}>
              <div class="row g-0">
                <div class="col-md-12 ml-5">
                  <h6 class="card-title font-weight-bold pt-2 text-white">QCL Logo Rich black CMYK-01.png</h6>
                  <div class="d-flex align-items-center">
                    <img src="/image/logo.png" class="img-fluid rounded-start" style={{ width: "200px" }} alt="..." />
                    <div class="d-flex kl" style={{ background: "#071A44", color: "#FFFFFF" }}>
                      <button type="button" class="btn btn-lg me-2 rounded" style={{ background: "#FFFFFF", color: "#071A44" }}>Read More</button>&nbsp;&nbsp;&nbsp;&nbsp;
                      <button type="button" class="btn btn-lg rounded" style={{ background: "#FFFFFF", color: "#071A44" }}>Download</button>
                    </div>


                  </div>
                  <p class="card-text pt-2 text-white">For print CMYK Rich blacks- unsuitable for newsprin</p>
                </div>


              </div>
            </div>

            <div class="card mb-3 ml-5" style={{ maxWidth: "1000px", background: "#071A44" }}>
              <div class="row g-0">
                <div class="col-md-12 ml-5">
                  <h6 class="card-title font-weight-bold pt-2 text-white">QCL Logo Rich black CMYK-01.png</h6>
                  <div class="d-flex align-items-center">
                    <img src="/image/logo.png" class="img-fluid rounded-start" style={{ width: "200px" }} alt="..." />
                    <div class="d-flex kl" style={{ background: "#071A44", color: "#FFFFFF" }}>
                      <button type="button" class="btn btn-lg me-2 rounded" style={{ background: "#FFFFFF", color: "#071A44" }}>Read More</button>&nbsp;&nbsp;&nbsp;&nbsp;
                      <button type="button" class="btn btn-lg rounded" style={{ background: "#FFFFFF", color: "#071A44" }}>Download</button>
                    </div>


                  </div>
                  <p class="card-text pt-2 text-white">For print CMYK Rich blacks- unsuitable for newsprin</p>
                </div>


              </div>
            </div>

          </div> */}
          {/* <!-- -------------------------GenderPayGap----------------- --> */}
          {/* <div id="GenderPayGap" class="page" style={{ color: "#FFFFFF" }}>
            <h1 >Gender Pay Gap</h1>
            <p >The reports below sets out our findings and provides some context to explain QCL gender pay gap figures.</p>
            <p>Download our Gender Pay Gap report 2022 here (pdf)</p>
            <p>Download our Gender Pay Gap report 2021 here (pdf)</p>
            <p>Download our Gender Pay Gap report 2020 here (pdf)</p>
            <p>Download our Gender Pay Gap report 2019 here (pdf)</p>
            <p>Download our Gender Pay Gap report 2018 here (pdf)</p>
            <p>Download our Gender Pay Gap report 2017 here (pdf)</p>
          </div> */}
          {/* <!-- ----------------------ModernSlaveryStatement--------------------- --> */}
          {/* <div id="ModernSlaveryStatement" class="page" style={{ color: "#FFFFFF" }}>
            <h1>Modern Slavery Statement</h1>
            <h4>Introduction</h4>
            <p>This statement is made pursuant to section 54 of the Modern Slavery Act 2015. It constitutes QCL slavery and human trafficking statement for the financial year ended 31 December 2018.</p>
            <h4>Our Organization</h4>
            <p>QCL was founded in London in 1992. We have stores in the UK, Spain, Ireland, India, Australia, Portugal, Netherlands, Mexico, Poland and Canarias.</p>
            <p>We buy, sell and exchange a range of technology and entertainment products including mobile phones, video games, DVDs and Blu-ray movies, computers, digital electronics, TVs and monitors, and music CDs.</p>
            <p>We have more than 700 stores worldwide and we are growing.</p>
            <h4>
              Our Supply Chain</h4>
            <p>As a second hand trade company our supply chain is the customer. We do however work with third party companies who act as a support service to our needs.</p>
            <h4>Policies</h4>
            <p>https://dev-portal.cex.webuy.dev/transfer-group-managerAs an expanding business, we are in the process of reviewing our policies in order to make sure that our third parties understand our expectations with regards to human rights across all areas of our supply chain.</p>
            <p>QCL actively encourages employees to report any activity that they believe is in breach of company human rights standards, for this we have a whistleblowing procedure in place to report any concerns about the way we and our third parties perform our tasks.</p>
            <h4>Due Diligence and Risk Assessment</h4>
            <p>QCL is fortunate that out supply is from the general public, but we still use support services to assist with the day to day running of our business. Many of these parties already have robust Due Diligence measures in place.</p>
            <p>As part of the ongoing improvement to QCL policies and standards, we are ensuring that we have a more comprehensive approach to risk assessment and identification of risk. In 2018 we made substantial improvements to our compliance department to assist in reviewing policies and procedures belonging to ourselves and to our third parties.</p>
            <p>Where applicable we visit our third parties to ensure their working conditions, policies and procedures are up to an excellent standard. Given the geographical location of some of our third parties, we are committed to understanding our relationships better by ensuring transparency with strong working contracts.</p>
            <h4>Measurement of Effectiveness</h4>
            <p>QCL will continue to monitor our third party support services both existing and new. Should any instances of non-compliance with the Act arise in relation to any of our third parties then this will be reviewed.</p>

          </div> */}
          {/* <!-- ---------------------------------19Response-------------------------- --> */}
          {/* <div id="OurCOVID-19Response" class="page" style={{ color: "#FFFFFF" }}>
            <h1>How QCL is responding to coronavirus (COVID-19)</h1>
            <p>As we look to open our stores again to the public we have composed this page to give you an idea of what has changed to ensure we follow government guidelines.</p>
            <h4>What to expect in our stores to keep you and our staff safe.
              Social distancing</h4>
            <p>During our busier times we may limit the amount of customers entering the store, where possible there will be queue guidance outside the store. To help you maintain a distance of two metres from other customers and staff, you'll find floor markings inside our stores to advise social distancing. We've also put up Perspex protector screens at our tills areas to keep our dedicated staff safe.</p>
            <p>PPE has also been stocked efficiently to ensure that safety options are available.</p>
            <p>We have also made careful alterations to our stores who operate with the option of using MePOS devices (used for Mobile customer engagement on the shop floor). All stores that have both MePOS and a Till system available will revert to only a Till system being used to assist with the safety of our customers and staff</p>
            <h4>Contactless payments & Vouchers</h4>
            <p>We are encouraging you to use contactless card payment where possible. The nationwide limit on contactless card payments has increased from £30 to £45 to reduce physical contact at checkout. We are of course fully honouring any vouchers you have in your possession.</p>
            <h4>Demoing of an item you wish to buy</h4>
            <p>Due to social distancing and the necessary changes to have both your safety and our staff's safety in mind, our staff will provide the demos behind the counter and will offer you the opportunity to inspect only once you have purchased. We apologise for the inconvenience this may cause but we hope this does not discourage you from purchasing your goods.</p>
            <h4>Trading in your goods consideration</h4>
            <p>We are still accepting your goods for cash or vouchers. What we ask in return is to be considerate in the cleanliness of the item when bringing into store. Where possible, sanitize your items and place in a bag so our staff can take out without any further interaction from yourself. We will, of course, clean the product before placing the item back out for sale.</p>
            <p>Where at all possible, we encourage you to accept a bank transfer for any exchange for cash. Vouchers will of course still be offered.</p>
            <h4>Changes to store operating hours</h4>
            <p>In these unprecedented times, our stores may change their opening hours as they see necessary. We are working hard to reflect these changes as quickly as possible on our store locator but please be aware, information may not be accurate.</p>
            <p>If you are feeling unwell and have any of the COVID-19 symptoms</p>
          </div> */}
          {/* <!-- ---------------------------------------CounterfeitProductsPolicy------------------- --> */}
          {/* <div id="CounterfeitProductsPolicy" class="page" style={{ color: "#FFFFFF" }}>
            <h1>Counterfeit Products Policy</h1>
            <p>We do not buy or sell counterfeit products in CeX in stores or via webuy.com.</p>
            <h4 class="pt-3">What is the policy?</h4>
            <p>Counterfeit products are illegal and not allowed on webuy.com or in CeX stores.</p>
            <h5>Examples include:</h5>
            <ul class="ml-3">
              <li>Electronics or accessories that use or bear the brand name, logo, or trademark of a company that didn't manufacture the item.</li>
              <li>Pirated or fake copies of movies, games or other software.</li>
            </ul>
            <p>Activity that doesn't follow CeX’s policy in regards to this may result in the closure of your account in addition to other actions.</p>
            <h4>Why does CeX have this policy?</h4>
            <p>To prevent the sale of counterfeit products, which are illegal and may impact on future customers</p>
          </div> */}
          {/* <!-- ---------------------------------Sell Games & Consoles--------------------- --> */}
          {/* <div id="SellTech" class="page">
            <div class="content-wrapper">
              <h1 style={{ color: "#FFFFFF" }}>Sell Games & Consoles</h1>
            </div>
            <h1 class="pt-3" style={{ color: "#FFFFFF" }}>Sell Games & Consoles for CASH</h1>
            <h6 style={{ color: "#FFFFFF" }}>Don’t let your unwanted consoles gather dust. Get top cash for them 
            in-store or online today! Click the buttons below to see how much you could get</h6>
            <br /><br />
            

            <div class="">
              <div class="row">
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/sell game/1.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">Switch</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/sell game/2.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">Xbox Series X</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/sell game/3.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">Xbox Series S</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/sell game/8.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">Wii</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br /><br />
            
            
            <div class="">
              <div class="row">
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/sell game/1.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">Switch</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/sell game/2.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">Xbox Series X</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/sell game/3.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">Xbox Series S</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/sell game/8.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">Wii</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div><br /><br />
            <div>
              <button type="button" class="btn btn-lg p-3 ml-4 pt-2 w-25 w-md-25" style={{ background: "#FFFFFF", color: "#071A44" }}>All other Game</button>
            </div>
          </div> */}
          {/* <!-- ------------------------------QCL--------------------- --> */}
          <div id="QCL" class="page ">
            <div class="content-wrapper">
              <h1 style={{ color: "#FFFFFF" }}>What is QCL?</h1>

              <img src="/image/main.png" alt="" className="imagesize" />
            </div>
            <br />
            <br />
            <br />
            <p class="paragraph ">
              QCL is the UK’s biggest second-hand electronics and entertainment
              specialist. You can buy, sell and exchange your games, phones,
              consoles, laptops, tablets, computers, electronics & gadgets for
              cash.Starting as a single games and computer specialist shop just
              off of London’s Tottenham Court Road in 1992, QCL has expanded
              over 30 years to more than 600 stores worldwide, spanning over 11
              countries including the UK, Ireland, Spain, Portugal, Mexico, The
              Canaries, Malaysia, The Netherlands, Australia, India and Italy.
              CeX has been serving tech enthusiasts, gamers and geeks the latest
              and greatest in the world’s tech and gaming - all with a 24 month
              warranty, for decades.At CeX you can get cash fast for your old
              phones, games, consoles, laptops, tablets, computers, technology,
              DVDs, Blu-Rays & gadgets, or get more with a QCL voucher and
              upgrade your old phones, consoles and laptops for less. With 385+
              stores in the UK, you can find your local store here.Upgrade your
              tech for less by trading your unwanted tech in at QCL, and
              grabbing yourself that new phone, new games, consoles, laptops and
              tech. You can shop in-store, online and now you can skip the
              queues and make sure you reserve your goodies with Click & Collect
              at QCL.
            </p>
            <p class="paragraph ">
              QCL is the UK’s biggest second-hand electronics and entertainment
              specialist. You can buy, sell and exchange your games, phones,
              consoles, laptops, tablets, computers, electronics & gadgets for
              cash.Starting as a single games and computer specialist shop just
              off of London’s Tottenham Court Road in 1992, QCL has expanded
              over 30 years to more than 600 stores worldwide, spanning over 11
              countries including the UK, Ireland, Spain, Portugal, Mexico, The
              Canaries, Malaysia, The Netherlands, Australia, India and Italy.
              CeX has been serving tech enthusiasts, gamers and geeks the latest
              and greatest in the world’s tech and gaming - all with a 24 month
              warranty, for decades.At CeX you can get cash fast for your old
              phones, games, consoles, laptops, tablets, computers, technology,
              DVDs, Blu-Rays & gadgets, or get more with a QCL voucher and
              upgrade your old phones, consoles and laptops for less. With 385+
              stores in the UK, you can find your local store here.Upgrade your
              tech for less by trading your unwanted tech in at QCL, and
              grabbing yourself that new phone, new games, consoles, laptops and
              tech. You can shop in-store, online and now you can skip the
              queues and make sure you reserve your goodies with Click & Collect
              at QCL
            </p>
            <p class="paragraph">
              CL is the UK’s biggest second-hand electronics and entertainment
              specialist. You can buy, sell and exchange your games, phones,
              consoles, laptops, tablets, computers, electronics & gadgets for
              cash.Starting as a single games and computer specialist shop just
              off of London’s Tottenham Court Road in 1992, QCL has expanded
              over 30 years to more than 600 stores worldwide, spanning over 11
              countries including the UK, Ireland, Spain, Portugal, Mexico, The
              Canaries, Malaysia, The Netherlands, Australia, India and Italy.
              CeX has been serving tech enthusiasts, gamers and geeks the latest
              and greatest in the world’s tech and gaming - all with a 24 month
              warranty, for decades.At CeX you can get cash fast for your old
              phones, games, consoles, laptops, tablets, computers, technology,
              DVDs, Blu-Rays & gadgets, or get more with a QCL voucher and
              upgrade your old phones, consoles and laptops for less. With 385+
              stores in the UK, you can find your local store here.Upgrade your
              tech for less by trading your unwanted tech in at QCL, and
              grabbing yourself that new phone, new games, consoles, laptops and
              tech. You can shop in-store, online and now you can skip the
              queues and make sure you reserve your goodies with Click & Collect
              at QCL.
            </p>
            <p class="paragraph">
              <p class="paragraph">
                QCL is the UK’s biggest second-hand electronics and
                entertainment specialist. You can buy, sell and exchange your
                games, phones, consoles, laptops, tablets, computers,
                electronics & gadgets for cash.Starting as a single games and
                computer specialist shop just off of London’s Tottenham Court
                Road in 1992, QCL has expanded over 30 years to more than 600
                stores worldwide, spanning over 11 countries including the UK,
                Ireland, Spain, Portugal, Mexico, The Canaries, Malaysia, The
                Netherlands, Australia, India and Italy. CeX has been serving
                tech enthusiasts, gamers and geeks the latest and greatest in
                the world’s tech and gaming - all with a 24 month warranty, for
                decades.At CeX you can get cash fast for your old phones, games,
                consoles, laptops, tablets, computers, technology, DVDs,
                Blu-Rays & gadgets, or get more with a QCL voucher and upgrade
                your old phones, consoles and laptops for less. With 385+ stores
                in the UK, you can find your local store here.Upgrade your tech
                for less by trading your unwanted tech in at QCL, and grabbing
                yourself that new phone, new games, consoles, laptops and tech.
                You can shop in-store, online and now you can skip the queues
                and make sure you reserve your goodies with Click & Collect at
                QCL.
              </p>
            </p>
          </div>

          {/* <!-- --------------------------------sell Tech------------------------ --> */}
          <div id="SellTech" class="page">
            <div class="content-wrapper">
              <h1 style={{ color: "#FFFFFF" }}>Sell Your Tech</h1>

              <img src="/image/main.png" alt="" className="imagesize" />
            </div>
            {/* <h1 class="pt-3" style={{ color: "#FFFFFF" }}>Sell Tech for CASH</h1> */}
            <p style={{ color: "#FFFFFF" }}>
              Looking to trade your unused tech for quick cash? Quick Cash
              Leicester offers a streamlined online solution with our "Sell Your
              Tech" service. Whether you have smartphones, tablets, laptops,
              gaming consoles, or other electronic devices you no longer need,
              our website provides a hassle-free platform to sell them from the
              comfort of your home. Here's how it works:
            </p>
            <h4 style={{ color: "#FFFFFF" }}>Submit Your Items:</h4>
            <p style={{ color: "#FFFFFF" }}>
              Start by visiting our website and navigating to the "Sell Your
              Tech" section. Here, you'll find a simple form where you can enter
              details about the tech items you wish to sell. Provide accurate
              information about the make, model, condition, and any accessories
              included.
            </p>
            <h4 style={{ color: "#FFFFFF" }}>Get a Quick Quote:</h4>
            <p style={{ color: "#FFFFFF" }}>
              {" "}
              Once you've submitted the details of your tech items, our system
              will generate a quick quote based on the information provided.
              This quote is an initial estimate of the value of your devices.
            </p>
            <h4 style={{ color: "#FFFFFF" }}>Send Your Tech:</h4>
            <p style={{ color: "#FFFFFF" }}>
              {" "}
              If you're satisfied with the initial quote, you can proceed to
              send your tech items to us. We'll provide you with a prepaid
              shipping label to make the process convenient and cost-effective
              for you.
            </p>
            <h4 style={{ color: "#FFFFFF" }}>Expert Evaluation:</h4>
            <p style={{ color: "#FFFFFF" }}>
              {" "}
              Upon receiving your tech items, our expert team will conduct a
              thorough evaluation to assess their condition and functionality.
              We'll ensure that each device is accurately appraised to determine
              its fair market value.
            </p>
            <h4 style={{ color: "#FFFFFF" }}>Receive a Final Offer:</h4>
            <p style={{ color: "#FFFFFF" }}>
              {" "}
              After the evaluation process, we'll provide you with a final offer
              for your tech items. This offer will be based on the actual
              condition of the devices and may differ from the initial quote.
              Rest assured, we strive to offer competitive prices for all tech
              items.
            </p>
            <h4 style={{ color: "#FFFFFF" }}>Accept the Offer:</h4>
            <p style={{ color: "#FFFFFF" }}>
              {" "}
              If you're happy with the final offer presented, you can proceed to
              accept it. We'll guide you through the necessary steps to complete
              the selling process, including providing any additional
              information required.
            </p>
            <h4 style={{ color: "#FFFFFF" }}>Get Paid Quickly:</h4>
            <p style={{ color: "#FFFFFF" }}>
              {" "}
              Once you've accepted the offer and completed the necessary
              paperwork, we'll initiate the payment process. Choose your
              preferred payment method, whether it's via bank transfer, PayPal,
              or another option, and receive your payment promptly.
            </p>
            <h4 style={{ color: "#FFFFFF" }}>Enjoy Your Cash:</h4>
            <p style={{ color: "#FFFFFF" }}>
              {" "}
              With the transaction completed, you can enjoy your cash earnings
              however you please. Whether you're looking to treat yourself to
              something new or save up for future expenses, the choice is yours.
            </p>
            <h4 style={{ color: "#FFFFFF" }}>Customer Support:</h4>
            <p style={{ color: "#FFFFFF" }}>
              {" "}
              Throughout the process, our dedicated customer support team is
              here to assist you with any questions or concerns you may have.
              We're committed to ensuring a smooth and satisfactory experience
              for every customer.
            </p>
            <p style={{ color: "#FFFFFF" }}>
              {" "}
              Sell your unused tech items with ease and convenience through
              Quick Cash Leicester's "Sell Your Tech" service on our website.
              Turn your gadgets into cash today!
            </p>

            <br />
            <br />

            {/* <div class="card-deck wimage">
              <div class="card" >
                <img class="card-img-top p-5" src="/image/laptop/1.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center text-white fs-6 rounded p-3" >Apple Laptop</h5>
                
                </div>

              </div>
              <div class="card" >
                <img class="card-img-top p-5" src="/image/laptop/2.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center text-white fs-6 rounded p-3" >Apple Desktop</h5>
                  
                </div>

              </div>
              <div class="card" >
                <img class="card-img-top p-5" src="/image/laptop/3.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center text-white fs-6 rounded p-3" >Apple Headphones</h5>
                 
                </div>

              </div>
              <div class="card" >
                <img class="card-img-top p-5" src="/image/laptop/4.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center text-white fs-6 rounded p-3" >Camera Lenses</h5>
                  
                </div>

              </div>

            </div><br /><br />
            
            <div class="card-deck wimage">
              <div class="card" >
                <img class="card-img-top p-5" src="/image/laptop/1.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center text-white fs-6 rounded p-3" >Apple Laptop</h5>
                </div>

              </div>
              <div class="card" >
                <img class="card-img-top p-5" src="/image/laptop/2.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center text-white fs-6 rounded p-3" >Apple Desktop</h5>
                </div>

              </div>
              <div class="card" >
                <img class="card-img-top p-5" src="/image/laptop/3.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center text-white fs-6 rounded p-3" >Apple Headphones</h5>
                </div>

              </div>
              <div class="card" >
                <img class="card-img-top p-5" src="/image/laptop/4.png" alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title text-center text-white fs-6 rounded p-3" >Camera Lenses</h5>
                </div>

              </div>

            </div>
            <h4 class="pt-4" className="fs-5 mt-5 mb-2" style={{ color: "#FFFFFF" }}>Join over 2 Million QCL App users today!</h4>
            <div class="row">
              <div class="col-12 col-md-4">
                <button type="button" class="btn btn-lg p-3 ml-4 pt-2 w-100 w-md-25" style={{ background: "#FFFFFF", color: "#071A44" }} >All other CASH</button>
              </div>
            </div> */}
          </div>

          {/* Sell Games and Consoles */}
          <div id="SellGames" class="page">
            <div class="content-wrapper">
              <h1 style={{ color: "#FFFFFF" }}>Sell Games & Consoles</h1>
            </div>
            <h1 class="pt-3" style={{ color: "#FFFFFF" }}>
              Sell Games & Consoles for CASH
            </h1>
            <h6 style={{ color: "#FFFFFF" }}>
              Don’t let your unwanted consoles gather dust. Get top cash for
              them in-store or online today! Click the buttons below to see how
              much you could get
            </h6>
            <br />
            <br />

            {/* <div class="">
              <div class="row">
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/sell game/1.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">Switch</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/sell game/2.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">Xbox Series X</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/sell game/3.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">Xbox Series S</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/sell game/8.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">Wii</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br /><br />
            
            
            <div class="">
              <div class="row">
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/sell game/1.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">Switch</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/sell game/2.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">Xbox Series X</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/sell game/3.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">Xbox Series S</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card">
                    <img class="card-img-top p-5" src="/image/sell game/8.png" alt="Card image cap" />
                    <div class="card-body">
                      <h5 class="card-title text-center text-white fs-6 rounded p-3">Wii</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div><br /><br />
            <div>
              <button type="button" class="btn btn-lg p-3 ml-4 pt-2 w-25 w-md-25" style={{ background: "#FFFFFF", color: "#071A44" }}>All other Game</button>
            </div> */}
          </div>
        </div>

        <br />
        <br />
        <div class="row justify-content-center mb-5"></div>
      </section>

      <Footer />
    </>
  );
};
export default Aboutus;
